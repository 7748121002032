import { useEffect, useState } from 'react';
import { visualizeAudio } from 'src/helpers';
import { AlertMediaAudioType } from 'src/models/Alerts.model';

export const useAudioRecording = () => {
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [audioUrl, setAudioUrl] = useState<string>();
  const [stream, setStream] = useState<MediaStream>();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>();
  const canvas = document.querySelector('.visualizer') as HTMLCanvasElement;

  const getMimeType = () => {
    const { audioWebm, audioMp4 } = AlertMediaAudioType;
    if (MediaRecorder.isTypeSupported(audioWebm)) {
      return audioWebm;
    } else if (MediaRecorder.isTypeSupported(audioMp4)) {
      return audioMp4;
    } else {
      throw new Error('No supported audio format found');
    }
  };

  const mimeType = getMimeType();

  const getUserAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(stream);

      const recorder = new MediaRecorder(stream, { mimeType });
      setMediaRecorder(recorder);
    } catch (error) {
      console.error('Error initializing audio recording:', error);
    }
  };

  const isRecording = mediaRecorder?.state === 'recording';

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start(1000);
      mediaRecorder.ondataavailable = e => {
        setChunks(prev => [...prev, e.data]);
      };
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      mediaRecorder.onstop = _e => {
        const blob = new Blob(chunks, { type: mediaRecorder.mimeType });
        const audioURL = URL.createObjectURL(blob);
        setAudioUrl(audioURL);
      };
    }
  };

  const releaseAudio = () => {
    mediaRecorder?.stream?.getTracks().forEach(track => track.stop());
    setChunks([]);
    setMediaRecorder(null);
  };

  useEffect(() => {
    getUserAudio();
  }, []);

  useEffect(() => {
    if (stream && canvas) visualizeAudio(stream, canvas, isRecording);
  }, [canvas, stream, isRecording]);

  return {
    startRecording,
    stopRecording,
    audioUrl,
    mediaRecorder,
    chunks,
    releaseAudio,
    isRecording,
    mimeType,
  };
};
