import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { MdClose, MdHome, MdSearch } from 'react-icons/md';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { getUsersPagination } from 'src/apis/users.api';
import { MapViewIcon } from 'src/assets';
import { NAVBAR_HEIGHT } from 'src/constants';
import { maxWidth } from 'src/constants/max-width.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import {
  FEED_POSTS_MAP_VIEW,
  HOME_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
  USER_PROFILE_ROUTE,
} from 'src/routes/routeList';
import { AppNavigationType } from 'src/types/navigation.type';
import { TopbarDropdownMenu } from '../TopbarDropdownMenu';

const GET_ALL_USERS_QUERY_KEY = 'users';

const PATHNAME_TO_EXCLUDE = [LOGIN_PAGE_ROUTE, REGISTER_PAGE_ROUTE];

interface TopbarProps {
  pageTitle: string;
  isFeedPostsPage?: boolean;
  feedId?: number;
  navigationType?: AppNavigationType;
}

const DEBOUNCE_TIME = 500;

export const Topbar: FC<TopbarProps> = ({
  pageTitle,
  isFeedPostsPage,
  feedId,
  navigationType,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const { currentUser } = useUserDetails();

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const FEED_MAPS_URL = FEED_POSTS_MAP_VIEW.replace(':id', _.toString(feedId));

  const { data: users } = useQuery<User[]>(
    [GET_ALL_USERS_QUERY_KEY, searchQuery],
    () => getUsersPagination(searchQuery),
  );

  const debouncedSearchTerm = useCallback(
    _.debounce(() => {
      setSearchQuery(searchInput);
    }, DEBOUNCE_TIME),
    [searchInput],
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  const handleSelect = (user: User | null) => {
    if (user) {
      history.push(USER_PROFILE_ROUTE.replace(':userId', `${user.id}`));
    }
  };

  const toggleSearch = () => {
    setIsSearch(o => !o);
  };

  useEffect(() => {
    debouncedSearchTerm();
    return debouncedSearchTerm.cancel;
  }, [debouncedSearchTerm, searchInput]);

  const getLeftButton = (): React.ReactNode => {
    if (location.pathname === HOME_PAGE_ROUTE) {
      return null;
    }

    if (navigationType === AppNavigationType.back) {
      return (
        <IconButton
          variant='ghost'
          borderRight='1px solid'
          borderColor='gray.200'
          _focus={{}}
          _hover={{}}
          size='lg'
          aria-label='Go back'
          icon={<Icon as={FiArrowLeft} boxSize='7' />}
          onClick={() => history.goBack()}
        />
      );
    }

    return (
      <IconButton
        variant='ghost'
        borderRight='1px solid'
        borderColor='gray.200'
        _focus={{}}
        _hover={{}}
        size='lg'
        as={Link}
        to={HOME_PAGE_ROUTE}
        aria-label='Go to home'
        icon={<Icon as={MdHome} boxSize='7' />}
      />
    );
  };

  return (
    <Center
      minW='full'
      position='fixed'
      zIndex='modal'
      top='0'
      h={NAVBAR_HEIGHT}
    >
      <Flex
        bg='gray.50'
        w='full'
        h='full'
        justifyContent='space-between'
        alignItems='center'
        boxShadow='lg'
        borderTop='1px solid'
        borderColor='gray.200'
        px='2'
        zIndex='200'
        maxW={maxWidth}
      >
        <Box flex='1'>{getLeftButton()}</Box>
        {isSearch ? (
          <Flex w='full'>
            <Box w='full'>
              <Select
                placeholder={translate(TranslationKeys.searchUsers)}
                components={{
                  NoOptionsMessage: () => {
                    if (_.isEmpty(searchQuery)) {
                      return (
                        <Text p='2' color='gray.500'>
                          {translate(TranslationKeys.startSearchingUsers)}
                        </Text>
                      );
                    }

                    return (
                      <Text p='2' color='gray.500'>
                        {translate(TranslationKeys.noUsersFound)}
                      </Text>
                    );
                  },
                }}
                autoFocus
                options={users}
                onInputChange={handleSearch}
                onChange={handleSelect}
                getOptionLabel={u => `${u.firstName} ${u.lastName}`}
                getOptionValue={u => u.email}
              />
            </Box>
            <IconButton aria-label='close-search' onClick={toggleSearch}>
              <MdClose />
            </IconButton>
          </Flex>
        ) : (
          <>
            <Text flex='2' fontWeight='bold' textAlign='center' noOfLines={1}>
              {pageTitle}
            </Text>

            <Flex flex='1' justifyContent='flex-end'>
              {currentUser && (
                <IconButton
                  icon={<Icon as={MdSearch} boxSize='6' />}
                  aria-label='search'
                  variant='ghost'
                  _focus={{ boxShadow: 'none' }}
                  _hover={{ background: 'none' }}
                  onClick={toggleSearch}
                  size='sm'
                  pr='6px'
                />
              )}

              {!!isFeedPostsPage && (
                <Link to={FEED_MAPS_URL}>
                  <IconButton
                    aria-label='posts-view'
                    icon={<Icon as={MapViewIcon} boxSize='6' />}
                    size='sm'
                    _focus={{ boxShadow: 'none' }}
                    _hover={{ background: 'none' }}
                    variant='ghost'
                    pr='6px'
                  />
                </Link>
              )}
              {/* don't show login button on the top bar when user is on the login or register page */}
              {!currentUser &&
                !_.includes(PATHNAME_TO_EXCLUDE, history.location.pathname) && (
                  <Link to={LOGIN_PAGE_ROUTE}>
                    <Button colorScheme='gray' size='sm'>
                      {translate(TranslationKeys.login)}
                    </Button>
                  </Link>
                )}
              <TopbarDropdownMenu />
            </Flex>
          </>
        )}
      </Flex>
    </Center>
  );
};
