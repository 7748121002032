import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import useCallSessions from './useCallSessions';

const useCallValidator = () => {
  const { callSessions } = useCallSessions();

  const allDisconnected = useMemo(() => {
    if (_.isEmpty(callSessions)) return false;

    return _.every(callSessions, session => Boolean(session.disconnectedAt));
  }, [callSessions]);

  const history = useHistory();
  useEffect(() => {
    if (allDisconnected) {
      history.goBack();
    }
  }, [allDisconnected, history]);
};

export default useCallValidator;
