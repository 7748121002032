import { useQuery } from 'react-query';
import { getTensorflowModelUrl } from 'src/apis/speech-recognition-api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

const TENSORFLOW_MODEL_URL_QUERY_KEY = 'tensorflow-model-url-query-key';

export type TensorflowModelUrl = {
  model: string;
  metaData: string;
  binaryFile1: string;
  binaryFile2: string;
};

export const useTensorflowModelUrl = (): {
  tensorflowModelUrl: TensorflowModelUrl;
} => {
  const { currentUser } = useUserDetails();

  const { data: tensorflowModelUrl } = useQuery(
    TENSORFLOW_MODEL_URL_QUERY_KEY,
    getTensorflowModelUrl,
    {
      enabled: !!currentUser,
    },
  );

  return { tensorflowModelUrl };
};
