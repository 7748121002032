import _ from 'lodash';
import { useCallback } from 'react';
import { QueryKey } from 'react-query';

import { useCustomQueryClient } from 'src/hoc/query-context';
import { ValueCallback } from 'src/types/common.type';

interface DataWithId {
  id: any;
}

interface UseArrayResponseQueryOptions {
  queryKey: QueryKey;
}

const concatUnique = <T extends DataWithId>(dataToAdd: T[], prevData: T[]) =>
  _.unionBy(dataToAdd, prevData, ({ id }) => id);

const useArrayDataQuery = <TData extends DataWithId>({
  queryKey,
}: UseArrayResponseQueryOptions) => {
  type DataOrUndefined = TData[] | undefined;
  const { queryClient } = useCustomQueryClient();

  const addOne = useCallback<ValueCallback<TData>>(
    addedData => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return concatUnique([addedData], prevData);
      });
    },
    [queryClient, queryKey],
  );

  const addMultiple = useCallback<ValueCallback<TData[]>>(
    addedData => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return concatUnique(addedData, prevData);
      });
    },
    [queryClient, queryKey],
  );

  const removeOne = useCallback<ValueCallback<number>>(
    id => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return _.filter(prevData, chat => chat.id !== id);
      });
    },
    [queryClient, queryKey],
  );

  const updateOne = useCallback<ValueCallback<TData>>(
    updatedData => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return _.map(prevData, data =>
          data.id === updatedData.id ? updatedData : data,
        );
      });
    },
    [queryClient, queryKey],
  );

  const updateMultiple = useCallback<ValueCallback<TData[]>>(
    updatedMultipleData => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return concatUnique(updatedMultipleData, prevData);
      });
    },
    [queryClient, queryKey],
  );

  const updatePartial = useCallback<ValueCallback<Partial<TData>>>(
    partialUpdate => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return _.map(prevData, data =>
          data.id === partialUpdate.id ? { ...data, ...partialUpdate } : data,
        );
      });
    },
    [queryClient, queryKey],
  );

  const updateMultiplePartial = useCallback<ValueCallback<Partial<TData>[]>>(
    updatedMultipleData => {
      queryClient.setQueryData<DataOrUndefined>(queryKey, prevData => {
        if (!prevData) {
          return prevData;
        }

        return _.map(prevData, data => {
          const toUpdate = _.find(
            updatedMultipleData,
            ({ id }) => data.id === id,
          );

          return { ...data, ...(toUpdate || {}) };
        });
      });
    },
    [queryClient, queryKey],
  );

  return {
    addOne,
    addMultiple,
    removeOne,
    updateOne,
    updateMultiple,
    updatePartial,
    updateMultiplePartial,
  };
};

export default useArrayDataQuery;
