import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Text,
  Spacer,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { MdSearch } from 'react-icons/md';
import { useQuery } from 'react-query';
import { getUsersPagination } from 'src/apis/users.api';
import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import { ValueCallback } from 'src/types/common.type';
import { getUserDisplayName } from 'src/utils/user.utils';
import PersonalVoiceCallButton from './PersonalVoiceCallButton';

type InputChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
type SearchCallback = ValueCallback<string>;

type SelectedUser = User & { selectedAt: Date };
export type SelectedUserMap = Record<string, SelectedUser>;

interface SearchUserToCallProps {
  searchLabel?: string;
  shouldRenderUser?: ValueCallback<User, boolean>;
}

export const useSelectedUsers = (initialState: SelectedUserMap = {}) =>
  useState<SelectedUserMap>(initialState);

const SearchUserToCall: React.FC<SearchUserToCallProps> = ({
  shouldRenderUser,
  searchLabel,
}) => {
  const { translate } = useTranslate();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>();

  const { data: userSearchResults, isLoading: isSearchingUsers } = useQuery<
    User[]
  >(
    ['searchUsersForNewCall', searchQuery],
    () => getUsersPagination(searchQuery),
    { enabled: !!searchQuery },
  );

  // React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearch = useCallback<SearchCallback>(
    _.debounce<SearchCallback>(setSearchQuery, 500),
    [setSearchQuery],
  );

  const onSearchInputChange: InputChangeHandler = e => {
    const searchInputValue = e.currentTarget.value?.trim();
    setSearchInputValue(searchInputValue);

    if (!_.isEmpty(searchInputValue)) {
      performSearch(searchInputValue);
    }
  };

  const canRenderUser: typeof shouldRenderUser = user => {
    // Always render if no predicate is provided
    return shouldRenderUser ? shouldRenderUser(user) : true;
  };

  return (
    <VStack spacing='6' align='start'>
      <FormControl>
        <FormLabel>{searchLabel}</FormLabel>
        <InputGroup>
          <InputLeftElement>
            <Icon as={MdSearch} color='gray.300' boxSize='6' />
          </InputLeftElement>
          <Input
            placeholder={translate(TranslationKeys.searchForAUser)}
            onChange={onSearchInputChange}
            value={searchInputValue}
          />
        </InputGroup>
      </FormControl>
      {_.isEmpty(userSearchResults) ? (
        !isSearchingUsers &&
        searchQuery && (
          <Text color='gray.500' fontSize='sm' pl='2'>
            {translate(TranslationKeys.noUsersFound)}
          </Text>
        )
      ) : (
        <UnorderedList spacing='4' w='full'>
          {userSearchResults &&
            userSearchResults.map(user => (
              <React.Fragment key={user.id}>
                {canRenderUser(user) && (
                  <ListItem as={HStack} cursor='pointer' spacing='4' w='full'>
                    <UserAvatar user={user} size='sm' />
                    <Text fontWeight='bold'>{getUserDisplayName(user)}</Text>
                    <Spacer />
                    <PersonalVoiceCallButton otherUserId={user.id} />
                  </ListItem>
                )}
              </React.Fragment>
            ))}
        </UnorderedList>
      )}
    </VStack>
  );
};

export default SearchUserToCall;
