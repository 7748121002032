import { Text, TextProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { CallSession } from 'src/models/CallSession.model';

interface CallConnectionStatusProps extends TextProps {
  callSession: CallSession;
}

const CallConnectionStatus: React.FC<CallConnectionStatusProps> = ({
  callSession,
  ...props
}) => {
  const { translate } = useTranslate();
  const [status, setStatus] = useState<string>(
    translate(TranslationKeys.connecting),
  );

  useEffect(() => {
    const { isUnreachable, hasMissed, hasRejected } = callSession;

    if (isUnreachable) {
      setStatus(translate(TranslationKeys.userUnreachable));
    }

    if (hasMissed) {
      setStatus(translate(TranslationKeys.callMissed));
    }

    if (hasRejected) {
      setStatus(translate(TranslationKeys.callRejected));
    }
  }, [callSession]);

  return <Text {...props}>{status}</Text>;
};

export default CallConnectionStatus;
