import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useCustomQueryClient } from 'src/hoc/query-context';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

import { getUnreadPersonalChatsCount } from '../apis/personal-message-threads.api';
import { MessageDto } from '../dto/messages.dto';
import { UnreadMessageThreadsCountDto } from '../dto/personal-messageThreads.dto';
import useNewMessageObserver from './useNewMessageObserver';

const kUnreadPersonalChatsQueryKey = 'getUnreadPersonalChatsCount';

const initialUnreadChatData: UnreadMessageThreadsCountDto = {
  threadIds: [],
  count: 0,
};

const useUnreadPersonalChatsQuery = () => {
  const { currentUser } = useUserDetails();
  const { data } = useQuery(
    kUnreadPersonalChatsQueryKey,
    getUnreadPersonalChatsCount,
    { enabled: !!currentUser },
  );

  return data || initialUnreadChatData;
};

const useUnreadPersonalChatsNewMessageObserver = (): void => {
  const { queryClient } = useCustomQueryClient();

  const handleNewMessage = useCallback(
    ({ MessageThreadId, isGroupMessage }: MessageDto) => {
      queryClient.setQueryData<UnreadMessageThreadsCountDto | undefined>(
        kUnreadPersonalChatsQueryKey,
        prev => {
          if (isGroupMessage) {
            return prev;
          }

          const prevData = prev || initialUnreadChatData;

          /**
           * If the message is from a thread that is already in the list of unread threads,
           * we don't need to do anything. The thread is already marked as unread.
           *
           * If the message is from a thread that is not in the list of unread threads,
           * we need to add it to the list of unread threads and increment the count.
           */
          const alreadyHasThread = prevData.threadIds.includes(MessageThreadId);
          if (alreadyHasThread) {
            return prevData;
          }

          return {
            threadIds: [...prevData.threadIds, MessageThreadId],
            count: prevData.count + 1,
          };
        },
      );
    },
    [queryClient],
  );
  useNewMessageObserver({ handleNewMessage });
};

const useUnreadPersonalChatsCount = (): number => {
  const unreadChats = useUnreadPersonalChatsQuery();
  useUnreadPersonalChatsNewMessageObserver();

  return unreadChats.count;
};

export default useUnreadPersonalChatsCount;
