import { useEffect } from 'react';
import { TextAudio } from 'src/apis/socket.io/messages.socket.io';

export const useMessageAudio = (
  textAudio: TextAudio | undefined,
  setTextAudio: (value: React.SetStateAction<TextAudio | undefined>) => void,
) => {
  let playingAudioArray: string[] = [];
  const audio = new Audio();

  audio.addEventListener('ended', () => {
    if (playingAudioArray.length) {
      audio.pause();

      setTimeout(() => {
        audio.currentTime = 0;
        audio.src = '';
        audio.src = playingAudioArray.shift() as unknown as string;
        audio.play();
      }, 200);

      return;
    }
    audio.src = '';
    audio.removeAttribute('src');
  });

  useEffect(() => {
    if (textAudio) {
      const blob = new Blob([textAudio.audio], { type: 'audio/wav' });

      const blobUrl = URL.createObjectURL(blob);

      if (blobUrl) {
        playingAudioArray.push(blobUrl);
      }
      if (!audio.src || audio.src === '') {
        audio.src = playingAudioArray.shift() as unknown as string;
        audio.play();
      }

      setTextAudio(undefined);
    }
  }, [textAudio]);

  return { audio, playingAudioArray };
};
