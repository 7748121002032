import { MdVideocam, MdVideocamOff } from 'react-icons/md';
import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';

import FooterIconButton from './FooterIconButton';

interface CallPageVideoButtonProps {
  isVideoDisabled: boolean;
}

const CallPageVideoButton: React.FC<CallPageVideoButtonProps> = ({
  isVideoDisabled,
}) => {
  const { toggleVideo, isVideoMuted } = usePeerConnection();

  return (
    <FooterIconButton
      activeIcon={MdVideocam}
      inactiveIcon={MdVideocamOff}
      onToggleClick={toggleVideo}
      isActive={!isVideoMuted}
      aria-label='Toggle video'
      disabled={isVideoDisabled}
    />
  );
};

export default CallPageVideoButton;
