import { CustomTabs } from 'src/components';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import useUnreadGroupChatsCount from 'src/hooks/useUnreadGroupChatsCount';
import useUnreadPersonalChatsCount from 'src/hooks/useUnreadPersonalChatsCount';

import GroupMessagesPanel from './panels/GroupMessagePanel';
import PersonalMessagesPanel from './panels/PersonalMessagesPanel';

const MessagesPage = () => {
  const { translate } = useTranslate();
  const unreadPersonalThreadsCount = useUnreadPersonalChatsCount();
  const unreadGroupThreadsCount = useUnreadGroupChatsCount();

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.messages)}>
      <CustomTabs
        firstTabPanel={<PersonalMessagesPanel />}
        secondTabPanel={<GroupMessagesPanel />}
        unreadGroupCount={unreadGroupThreadsCount}
        unreadPersonalCount={unreadPersonalThreadsCount}
      />
    </HeaderWrapper>
  );
};

export default MessagesPage;
