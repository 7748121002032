import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { draftPage } from 'src/apis/pages.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { DraftPageDto } from 'src/dto/pages.dto';
import { useTranslate } from 'src/hooks/useTranslate';
import { Page } from 'src/models/Page.model';
import { PageDraft } from 'src/models/pageDraft';
import { EditablePageContent } from '../types/EditablePageContent.type';

interface UsePageContentForReview extends Pick<Page, 'id'> {}

const usePageContentForReview = ({ id }: UsePageContentForReview) => {
  const { translate } = useTranslate();
  const showToast = useToast();
  const history = useHistory();

  const reviewPageContentMutation = useMutation<
    PageDraft,
    AxiosError,
    DraftPageDto
  >(draftPage, {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: translate(TranslationKeys.yourReviewSubmitted),
        description: translate(TranslationKeys.waitForAdminReview),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: translate(TranslationKeys.errorSubmittingYourReview),
        description: translate(TranslationKeys.pleaseTryAgain),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleReviewPageContent = (draft: EditablePageContent | undefined) => {
    if (!draft) {
      return;
    }
    reviewPageContentMutation.mutate({
      draftContent: JSON.stringify(draft),
      PageId: id,
    });
  };

  return { handleReviewPageContent, reviewPageContentMutation };
};

export default usePageContentForReview;
