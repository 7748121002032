import { SimpleGrid } from '@chakra-ui/react';

import { CUSTOM_SCROLL_BAR_CSS, NO_USER_IN_CALL } from 'src/constants';
import { CALL_PAGE_HEIGHT } from 'src/constants/group-call.constant';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

interface GroupCallGridWrapperProps {
  heightRef: React.RefObject<HTMLDivElement>;
}

export const GroupCallGridWrapper: React.FC<GroupCallGridWrapperProps> = ({
  children,
  heightRef,
}) => {
  const { callSessions } = useCallSessions();

  return (
    <SimpleGrid
      bgColor='black'
      gap='2'
      color='white'
      overflowY='auto'
      css={CUSTOM_SCROLL_BAR_CSS}
      alignItems='center'
      columns={callSessions.length === NO_USER_IN_CALL ? 1 : 2}
      h={CALL_PAGE_HEIGHT}
      ref={heightRef}
    >
      {children}
    </SimpleGrid>
  );
};
