import { Box, BoxProps } from '@chakra-ui/react';
import Slider from 'react-slick';
import _ from 'lodash';

import { Post } from '../../../models/Post.model';
import PostMediaPreview from './PostMediaPreview';
import { valueContainingOne } from './constants/valueContaingOne.constant';

interface PostCardMediaProps extends BoxProps {
  post: Post;
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        color: 'black',
        right: '0px',
        zIndex: '100',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '0px',
        zIndex: '100',
      }}
      onClick={onClick}
    />
  );
}

const PostCardMedia: React.FC<PostCardMediaProps> = ({ post, ...props }) => {
  if (_.isEmpty(post.postMedia)) {
    return null;
  }

  // bottom padding to fix the dots of the slider
  return (
    <Box pb='4' {...props}>
      {(post.postMedia.length > valueContainingOne && (
        <Box maxH='52' minH='24' overflowY='scroll' scrollBehavior='smooth'>
          {post.postMedia &&
            post.postMedia.map(media => (
              <PostMediaPreview media={media} key={media.id} />
            ))}
        </Box>
      )) || (
        <Box maxH='52' minH='24'>
          {post.postMedia &&
            post.postMedia.map(media => (
              <PostMediaPreview media={media} key={media.id} />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default PostCardMedia;
