import { Tbody, Td } from '@chakra-ui/react';

import { listVariants } from 'src/constants/calls.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PersonalCalls } from 'src/models/PersonalCall';

import CallIcons from './CallIcons';
import { getColorForCallIcon, getDuration } from '../utils/call.util';
import { ColorShaderMap } from 'src/utils';

export interface CallHistoryItemProps {
  callerId: number;
  receiverId: number;
  joinedAt: Date | null;
  hasRejectedCall: boolean | null;
  hasMissedCall?: boolean;
  isUnreachable: boolean;
  subtitle: React.ReactNode;
  isVisible: boolean;
  personalCalls?: PersonalCalls[];
  disconnectedAt: Date | null;
}

const CallHistoryItem: React.FC<CallHistoryItemProps> = ({
  callerId,
  receiverId,
  joinedAt,
  hasRejectedCall,
  hasMissedCall,
  isUnreachable,
  subtitle,
  disconnectedAt,
}) => {
  // get current user
  const { currentUser } = useUserDetails();

  return (
    <Tbody
      variants={listVariants}
      borderBottom={'1px solid'}
      borderBottomColor={ColorShaderMap.gray[200]}
      py='2'
      px='2'
    >
      <Td>{subtitle}</Td>

      <Td>
        {currentUser && (
          <CallIcons
            joinedAt={joinedAt}
            callerId={callerId}
            currentUser={currentUser}
            receiverId={receiverId}
            hasMissedCall={hasMissedCall}
            hasRejectedCall={hasRejectedCall}
            isUnreachable={isUnreachable}
            color={getColorForCallIcon(joinedAt)}
          />
        )}
      </Td>

      <Td>
        {joinedAt && disconnectedAt
          ? getDuration(joinedAt, disconnectedAt)
          : '-'}
      </Td>
    </Tbody>
  );
};

export default CallHistoryItem;
