import { Icon, IconButton, useToast } from '@chakra-ui/react';
import _ from 'lodash';
import { IoCallOutline } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { getOneMessageThread } from 'src/apis/message-threads.api';
import {
  MAXIMUM_MEMBERS_IN_GROUP_CALL,
  NO_MEMBERS_IN_GROUP,
} from 'src/constants/group-call.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useDialog from 'src/hooks/useDialog';
import useGroupCall from 'src/hooks/useGroupCall';
import { useTranslate } from 'src/hooks/useTranslate';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';
import SelectMemberForGroupCallDialog from './SelectParticipantsModal';

interface GroupCallButtonProps {
  groupMessageThread: GroupMessageThread;
}

const GroupCallButton: React.FC<GroupCallButtonProps> = ({
  groupMessageThread,
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();

  const toast = useToast();

  const [
    isSelectMemberDialogOpen,
    openSelectMemberDialog,
    closeSelectMemberDialog,
  ] = useDialog();

  const getMessageThreadQueryKey = ['getMessageThread', groupMessageThread.id];
  const { data: messageThread } = useQuery(
    getMessageThreadQueryKey,
    async () => {
      return await getOneMessageThread(groupMessageThread.id);
    },
  );

  // filter current user from message thread member
  const messageThreadMembersWithoutCurrentUser = messageThread?.members.filter(
    (member, index) => {
      return member.user?.id !== currentUser?.id;
    },
  );

  const existingMemberUserId = _.map(
    messageThreadMembersWithoutCurrentUser,
    ({ UserId }) => UserId,
  );

  const { makeGroupCall } = useGroupCall();

  const handleCallForExistingMember = (
    userId: number[],
    MessageGroupId: number,
  ) => {
    makeGroupCall({
      receiverUserIds: userId,
      MessageGroupId,
    });
  };

  if (!currentUser) {
    return null;
  }

  return (
    <>
      {(existingMemberUserId.length > MAXIMUM_MEMBERS_IN_GROUP_CALL && (
        <IconButton
          aria-label={`Make Group Call in ${
            groupMessageThread?.group?.name || 'this group'
          }`}
          icon={<Icon as={IoCallOutline} boxSize='6' />}
          onClick={openSelectMemberDialog}
        />
      )) ||
        (existingMemberUserId.length < MAXIMUM_MEMBERS_IN_GROUP_CALL && (
          <IconButton
            aria-label={`Make Group Call in ${
              groupMessageThread?.group?.name || 'this group'
            }`}
            icon={<Icon as={IoCallOutline} boxSize='6' />}
            onClick={openSelectMemberDialog}
          />
        )) ||
        (existingMemberUserId.length === NO_MEMBERS_IN_GROUP && (
          <IconButton
            aria-label={`Make Group Call in ${
              groupMessageThread?.group?.name || 'this group'
            }`}
            icon={<Icon as={IoCallOutline} boxSize='6' />}
            onClick={() =>
              toast({
                title: translate(TranslationKeys.emptyGroupMessage),
                description: translate(TranslationKeys.groupCallWarning),
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
            }
          />
        )) ||
        (groupMessageThread && (
          <IconButton
            aria-label={`Make Group Call in ${
              groupMessageThread?.group?.name || 'this group'
            }`}
            icon={<Icon as={IoCallOutline} boxSize='6' />}
            onClick={() => {
              handleCallForExistingMember(
                existingMemberUserId,
                groupMessageThread?.group?.id,
              );
            }}
          />
        ))}

      {messageThread && (
        <SelectMemberForGroupCallDialog
          isOpen={isSelectMemberDialogOpen}
          onClose={closeSelectMemberDialog}
          messageThread={messageThread}
          groupMessageThreadId={groupMessageThread.group.id}
        />
      )}
    </>
  );
};

export default GroupCallButton;
