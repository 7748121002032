import { Box, Center } from '@chakra-ui/react';
import { useEffect } from 'react';

import { maxWidth } from 'src/constants/max-width.constant';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider';
import { useQueryLoaderContext } from 'src/hoc/query-context';

import useCallParams from './hooks/useCallParams';
import useCallValidator from './hooks/useCallValidator';
import PeerConnectionProvider from './provider/peer-connection';
import CallPageHeader from './components/header';
import CallPageBody from './components/body/CallPageBody';
import CallPageFooter from './components/footer/CallPageFooter';
import useNewCallSessionsUpdates from './hooks/useCallSessionUpdates/useCallSessionsUpdates';
import UserMediaProvider from './components/footer/context/user-media/provider/UserMedia.provider';

const PeerCallPage: React.FC = () => {
  const { setIsListening } = useHandsFreeOperation();
  const { setIsLoaderRequired } = useQueryLoaderContext();
  useNewCallSessionsUpdates();
  useCallValidator();

  useEffect(() => {
    setIsListening(false);
    setIsLoaderRequired(false);

    return () => {
      setIsListening(true);
      setIsLoaderRequired(true);
    };
  }, []);

  return (
    <Center>
      <Box h='100svh' w='full' maxW={maxWidth} color='white'>
        <CallPageHeader />
        <UserMediaProvider>
          <PeerConnectionProvider>
            <CallPageBody />
            <CallPageFooter />
          </PeerConnectionProvider>
        </UserMediaProvider>
      </Box>
    </Center>
  );
};

const PeerCallPageWrapper = () => {
  const { callId } = useCallParams();

  return <PeerCallPage key={`call-page-${callId}`} />;
};

export default PeerCallPageWrapper;
