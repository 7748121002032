import { DataConnection } from 'peerjs';
import { useState } from 'react';

import { DataChannelsEventsMessage } from '../components/footer/context/data-channels.constants';

const usePeerDataConnection = () => {
  const [peerAudioStatus, setPeerAudioStatus] = useState<
    Record<number, boolean>
  >({});
  const [peerVideoStatus, setPeerVideoStatus] = useState<
    Record<number, boolean>
  >({});

  const handleDataChannelMessage = (dataChannel: DataConnection) => {
    dataChannel.on('data', dataChannelMessageForVideoAndAudioStatus => {
      const { message, senderId } = dataChannelMessageForVideoAndAudioStatus;

      if (message === DataChannelsEventsMessage.enableVideo) {
        setPeerVideoStatus(prev => ({
          ...prev,
          [senderId]: true,
        }));
      } else if (message === DataChannelsEventsMessage.disableVideo) {
        setPeerVideoStatus(prev => ({
          ...prev,
          [senderId]: false,
        }));
      }
      if (message === DataChannelsEventsMessage.enableMic) {
        setPeerAudioStatus(prev => ({
          ...prev,
          [senderId]: true,
        }));
      } else if (message === DataChannelsEventsMessage.disableMic) {
        setPeerAudioStatus(prev => ({
          ...prev,
          [senderId]: false,
        }));
      }
    });
  };

  return {
    handleDataChannelMessage,
    peerAudioStatus,
    peerVideoStatus,
  };
};

export default usePeerDataConnection;
