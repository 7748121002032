import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/modal';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import SearchUserToCall from './SearchUserToCall';

interface SelectUserToCallModalProps extends Omit<ModalProps, 'children'> {}

const SelectUserToCallModal: React.FC<SelectUserToCallModalProps> = ({
  ...props
}) => {
  const { translate } = useTranslate();
  return (
    <Modal scrollBehavior='inside' isCentered {...props}>
      <ModalOverlay />
      <ModalContent minH='50%' mx='4'>
        <ModalHeader>{translate(TranslationKeys.selectUserToCall)}</ModalHeader>
        <ModalBody>
          <SearchUserToCall />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectUserToCallModal;
