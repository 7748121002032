import _ from 'lodash';
import { useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { MdVolumeUp, MdVolumeOff } from 'react-icons/md';

import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { getOtherUser } from 'src/pages/peer-call/utils/call-session.utils';

import FooterIconButton from './FooterIconButton';

interface CallPageSpeakerButtonProps {
  isSpeakerDisabled: boolean;
}

const CallPageSpeakerButton: React.FC<CallPageSpeakerButtonProps> = ({
  isSpeakerDisabled,
}) => {
  const [isSpeakerOn, { toggle: toggleSpeaker }] = useBoolean(true);
  const { unmutePeerStream, mutePeerStream } = usePeerConnection();
  const { callSessions } = useCallSessions();
  const { currentUser } = useUserDetails();

  useEffect(() => {
    if (!callSessions) {
      return;
    }

    if (isSpeakerOn) {
      _.map(callSessions, callSession => {
        const otherUser = getOtherUser(callSession, currentUser?.id!);
        unmutePeerStream(otherUser?.id!);
      });
    } else {
      _.map(callSessions, callSession => {
        const otherUser = getOtherUser(callSession, currentUser?.id!);
        mutePeerStream(otherUser?.id!);
      });
    }
  }, [isSpeakerOn, callSessions, mutePeerStream, unmutePeerStream]);

  return (
    <FooterIconButton
      activeIcon={MdVolumeUp}
      inactiveIcon={MdVolumeOff}
      onToggleClick={toggleSpeaker}
      isActive={isSpeakerOn}
      aria-label='Toggle volume'
      disabled={isSpeakerDisabled}
    />
  );
};

export default CallPageSpeakerButton;
