import { UseToastOptions } from '@chakra-ui/react';
import axios from 'axios';
import { TFunction } from 'i18next';
import { TranslationKeys } from 'src/constants/translation-keys';

export const getAPIErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.message;
  }

  return TranslationKeys.somethingWentWrong;
};

/**
 *
 * @param error: It is axios error which return when any query get failed
 * @function showErrorAlertMessage: it is use to show the error message toast when any mutation query get failed
 */
export const showErrorAlertMessage = (
  error: unknown,
  showToast: ({
    title,
    status,
  }: Pick<UseToastOptions, 'title' | 'status'>) => void,
  translate: TFunction<['translation', ...string[]], undefined>,
): void => {
  if (axios.isAxiosError(error)) {
    showToast({
      title: translate(getAPIErrorMessage(error)),
      status: 'error',
    });
  }
};
