import { Box, BoxProps } from '@chakra-ui/react';
import _ from 'lodash';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { CallSession } from 'src/models/CallSession.model';
import { CallMicStatus } from 'src/pages/peer-call/components/body/components/CallMicStatus';
import CallUserImage from 'src/pages/peer-call/components/body/components/CallUserImage';
import PeerVideo from 'src/pages/peer-call/components/body/components/PeerVideo';
import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';
import { getOtherUser } from 'src/pages/peer-call/utils/call-session.utils';
import { CallType } from 'src/types/call.type';

interface ReceiversStreamProps extends BoxProps {}

export const ReceiversStream: React.FC<ReceiversStreamProps> = props => {
  const { callSessions } = useCallSessions();
  const { currentUser } = useUserDetails();
  const {
    peerStreams,
    peerVideoStatus,
    peerAudioStatus: peerMicStatus,
  } = usePeerConnection();

  return (
    <>
      {/* 
      - map call session and get peerStreams by session id
      - if there is streams so display that stream otherwise callUser Image will be display
       */}
      {_.map(callSessions, (session: CallSession) => {
        const otherUser =
          session && currentUser && getOtherUser(session, currentUser.id);
        const stream = otherUser && peerStreams[otherUser.id];
        const isPeerVideoEnabled = otherUser && peerVideoStatus[otherUser.id];
        const isPeerMicEnabled = otherUser && peerMicStatus[otherUser.id];

        return (
          <Box
            h='full'
            w='full'
            position='relative'
            key={session.id}
            {...props}
          >
            {stream ? (
              <PeerVideo
                key={session.id}
                stream={stream}
                user={otherUser}
                peerVideoStatus={isPeerVideoEnabled}
                session={session}
              />
            ) : (
              <CallUserImage
                key={otherUser?.id}
                receiver={otherUser}
                joinedCall={session.joinedAt}
              />
            )}
            <CallMicStatus
              isPeerMicEnabled={isPeerMicEnabled}
              callType={CallType.Group}
            />
          </Box>
        );
      })}
    </>
  );
};
