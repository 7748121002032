import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';

import { updateMessageStatus } from 'src/apis/messages.api';
import {
  onNewMessage,
  unsubscribeNewMessage,
} from 'src/apis/socket.io/messages.socket.io';
import { MessageDto } from 'src/dto/messages.dto';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { Message } from 'src/models/Message.model';
import { ValueCallback } from 'src/types/common.type';

import useMessagesDataMethods from './useMessagesDataMethods';

const useNewMessageObserver = (messageThreadId: number) => {
  const { addOne, updateOne } = useMessagesDataMethods(messageThreadId);
  const { currentUser } = useUserDetails();

  const makeMessageSeenMutation = useMutation(
    (message: Message) =>
      updateMessageStatus(message.MessageThreadId, message.id, 'seen'),
    { onSuccess: (_, message) => updateOne({ ...message, status: 'seen' }) },
  );

  const handleNewMessage = useCallback<ValueCallback<MessageDto>>(
    newMessage => {
      // If the message is not for the current thread, ignore it
      if (newMessage.MessageThreadId !== messageThreadId) return;

      const msg = {
        ...newMessage,
        createdAt: new Date(newMessage.createdAt),
        messageAt: new Date(newMessage.createdAt),
      };

      addOne(msg);

      if (newMessage.SenderId !== currentUser?.id) {
        makeMessageSeenMutation.mutate(msg);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id],
  );

  useEffect(() => {
    onNewMessage(handleNewMessage);

    return () => unsubscribeNewMessage(handleNewMessage);
  }, [handleNewMessage]);
};

export default useNewMessageObserver;
