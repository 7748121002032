import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { useRef } from 'react';
import { FiDownload } from 'react-icons/fi';
import { downloadFile } from '../utils/download-file.utils';

interface DownloadButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  file: { type: string; url: string };
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ file, ...props }) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const onClick = () =>
    downloadFile({ anchorRef, url: file.url, mimeType: file.type });

  return (
    <>
      <IconButton
        as='a'
        cursor='pointer'
        aria-label='download file'
        isRound
        p='0'
        icon={<FiDownload />}
        onClick={onClick}
        {...props}
      />
      <a href='##' ref={anchorRef} style={{ display: 'none' }} download />
    </>
  );
};

export default DownloadButton;
