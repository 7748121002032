import { VStack, Text } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

const EmptyGroupChats: React.FC = () => {
  const { translate } = useTranslate();
  return (
    <VStack mt='12'>
      <Text fontSize='lg' color='gray.500' fontWeight='semibold'>
        {translate(TranslationKeys.noGroupChatsYet)}
      </Text>
      <Text fontSize='sm' color='gray.500'>
        {translate(TranslationKeys.createGroupChat)}
      </Text>
    </VStack>
  );
};

export default EmptyGroupChats;
