import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';

import { sendCommandToNlpServer } from 'src/apis/speech-recognition-api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { UserLocationContext } from 'src/hoc/UserLocationContext/user-location.context';
import { useCommonAudioStream } from 'src/hooks/useCommonAudioStream';
import { useTranslate } from 'src/hooks/useTranslate';
import { getAccessToken } from 'src/providers/auth-store.provider';

import { useHandsFreeOperationSetting } from '../hands-free-operation-setting.provider';
import { useHandsFreeOperation } from '../hands-free-operation.provider';
import { useHFOCommandExecution } from './use-execute-hfo-command';
import { useTranscribeMessage } from './use-transcribe-message';

export const useHFOAudioStream = () => {
  const { translate } = useTranslate();
  const showToast = useToast();

  const { currentUser: user } = useUserDetails();
  const accessToken = getAccessToken();
  const { hfoModelDisclosure, setIsListening } = useHandsFreeOperation();
  const {
    isEndOfUtteranceDetected,
    setStartTime,
    transcribeMessage,
    setTranscribeMessage,
  } = useTranscribeMessage();
  const { transcribeLanguage } = useHandsFreeOperationSetting();
  const { audioRecorder, isRecording, stopRecording, endRecording } =
    useCommonAudioStream(setStartTime, setTranscribeMessage);

  const { executeCommand } = useHFOCommandExecution();
  const { locationStream } = useContext(UserLocationContext);

  const { mutate: sendCommandToNlpServerMutate } = useMutation(
    sendCommandToNlpServer,
  );

  const onSuccessOrError = () => {
    endRecording();
    stopRecording();
    setIsListening(true);
    hfoModelDisclosure.onClose();
  };

  const handleSend = async () => {
    if (user && accessToken) {
      sendCommandToNlpServerMutate(
        {
          UserId: user?.id,
          language: transcribeLanguage,
          text: transcribeMessage,
          accessToken,
          // used for sos command
          latitude: locationStream?.latitude.toString(),
          longitude: locationStream?.longitude.toString(),
        },
        {
          onSuccess: data => {
            executeCommand(data);
            onSuccessOrError();
          },
          onError: error => {
            showToast({
              status: 'error',
              title: translate((error as AxiosError).response?.data.message),
            });
            onSuccessOrError();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (audioRecorder && isEndOfUtteranceDetected && isRecording) {
      handleSend();
    }
  }, [audioRecorder, isRecording, isEndOfUtteranceDetected]);

  return {
    isEndOfUtteranceDetected,
    transcribeMessage,
    stopRecording,
  };
};
