import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CUSTOM_SCROLL_BAR_CSS,
  NAVBAR_HEIGHT,
  PAGE_HEIGHT_WITH_NAVBAR,
  PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER,
} from 'src/constants';
import { HomePageNavbar } from 'src/pages/home/components/HomePageNavbar';
import { HOME_PAGE_ROUTE } from 'src/routes/routeList';
import { AppNavigationType } from 'src/types/navigation.type';
import { Footer, Topbar } from '../components';
import { maxWidth, minWidth } from '../constants/max-width.constant';
import { useUserDetails } from './UserDetailsProvider';

interface HeaderWrapperProps {
  pageTitle: string;
  /**
   * For now, to ensure the maxWidth and center alignment adjustments are not mistakenly hampered
   * by passing in the `maxW` or `mx` props, we need to make sure those are not passed in.
   */
  bodyContainerProps?: Omit<BoxProps, 'maxW' | 'mx'>;
  isFeedPostsPage?: boolean;
  feedId?: number;
  navigationType?: AppNavigationType;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({
  children,
  pageTitle,
  bodyContainerProps,
  isFeedPostsPage = false,
  feedId,
  navigationType,
}) => {
  const { currentUser } = useUserDetails();
  const { location } = useHistory();

  const isHomePage = location.pathname === HOME_PAGE_ROUTE;

  return (
    <Box w='100wh' h='100svh' overflow='hidden'>
      {isHomePage && currentUser ? (
        <HomePageNavbar />
      ) : (
        <Topbar
          pageTitle={pageTitle}
          isFeedPostsPage={isFeedPostsPage}
          feedId={feedId}
          navigationType={navigationType}
        />
      )}
      <Box
        position='relative'
        overflowY='auto'
        overflowX='hidden'
        borderLeft='1px solid'
        borderRight='1px solid'
        borderColor='gray.200'
        css={CUSTOM_SCROLL_BAR_CSS}
        mt={NAVBAR_HEIGHT}
        height={
          isHomePage
            ? PAGE_HEIGHT_WITH_NAVBAR
            : PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER
        }
        mx='auto'
        minW={minWidth}
        maxW={maxWidth}
        {...(bodyContainerProps || {})}
      >
        {children}
      </Box>
      {/* we don,t need to show the navigation footer on the home page */}
      {!isHomePage && <Footer />}
    </Box>
  );
};

export default HeaderWrapper;
