import { useBoolean } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { postTextMessage } from 'src/apis/messages.api';
import {
  onMessageTranscribed,
  TranscribeMessage,
  unsubscribeOnMessageTranscribed,
} from 'src/apis/socket.io/messages.socket.io';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { TRANSCRIBE_LANGUAGE } from 'src/pages/messages/constants/language.constant';
import { ValueCallback } from 'src/types/common.type';

import { useCommonAudioStream } from './useCommonAudioStream';

export const useASRAudio = (
  messageThreadId: number,
  onClose: () => void,
  onMessageSend: ValueCallback<string>,
) => {
  const { setStartTime, transcribeMessage, setTranscribeMessage } =
    useTranscribeMessage(messageThreadId);
  const { transcribeLanguage } = useHandsFreeOperationSetting();

  const [seletecLanguage, setSeletecLanguage] = useState<
    TRANSCRIBE_LANGUAGE | undefined
  >(transcribeLanguage);

  const { isRecording, resetAudioRecorder, startRecording, stopRecording } =
    useCommonAudioStream(
      setStartTime,
      setTranscribeMessage,
      messageThreadId,
      seletecLanguage,
    );

  const { mutate: messageMutate } = useMutation(postTextMessage);

  const [edit, setEdit] = useBoolean(false);

  const handleLanguageChange = (language: TRANSCRIBE_LANGUAGE) => {
    setSeletecLanguage(language);
    resetAudioRecorder(() => {
      startRecording(() => {
        setEdit.off();
      });
    });
  };

  const onTranscriptionSend = () => {
    stopRecording();
    if (transcribeMessage.trim().length === 0) {
      onClose();
      return;
    }
    onMessageSend(`${transcribeMessage}`);
    messageMutate(
      { messageThreadId, text: transcribeMessage },
      { onSuccess: () => onClose() },
    );
  };

  const handleEdit = () => setEdit.on();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTranscribeMessage(event.target.value);
  };

  const restartRecording = () => {
    setTranscribeMessage('');
    startRecording(() => {
      setEdit.off();
    });
  };

  const resetState = () => {
    resetAudioRecorder();
  };

  return {
    handleLanguageChange,
    isRecording,
    transcribeMessage,
    handleInputChange,
    edit,
    restartRecording,
    resetState,
    handleEdit,
    onTranscriptionSend,
  };
};

const useTranscribeMessage = (messageThreadId: number) => {
  const { currentUser } = useUserDetails();

  const [startTime, setStartTime] = useState<number>(0);
  const [receivedMessage, setReceivedMessage] = useState<TranscribeMessage>();
  const [transcribeMessage, setTranscribeMessage] = useState<string>('');

  const handleTranscribeMessage = useCallback<ValueCallback<TranscribeMessage>>(
    newMessage => {
      if (currentUser?.id === newMessage.userId) {
        setReceivedMessage(newMessage);
      }
    },
    [],
  );

  useEffect(() => {
    if (receivedMessage && startTime && receivedMessage.timestamp < startTime) {
      return;
    }

    setTranscribeMessage(receivedMessage?.text || '');
  }, [receivedMessage, startTime]);

  useEffect(() => {
    if (messageThreadId) {
      onMessageTranscribed(handleTranscribeMessage);
    }

    return () => {
      unsubscribeOnMessageTranscribed(handleTranscribeMessage);
    };
  }, [handleTranscribeMessage, messageThreadId]);

  return { setStartTime, transcribeMessage, setTranscribeMessage };
};
