import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import React from 'react';
import { BsMicFill } from 'react-icons/bs';
import { ChakraAnimationBox } from 'src/components';
import {
  AUDIO_MIC_ANIMATION,
  AUDIO_MIC_ANIMATION_TRANSITION,
} from 'src/constants/speech-recognition.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider/hands-free-operation.provider';
import { useHFOAudioStream } from 'src/hoc/HandsFreeOperationProvider/hooks/use-hfo-audio';
import { useTranslate } from 'src/hooks/useTranslate';

export const WakeWordDetectionDialog: React.FC = () => {
  const { translate } = useTranslate();
  const { hfoModelDisclosure } = useHandsFreeOperation();
  const { isEndOfUtteranceDetected, transcribeMessage, stopRecording } =
    useHFOAudioStream();

  return (
    <Modal
      {...hfoModelDisclosure}
      onClose={() => {
        stopRecording();
        hfoModelDisclosure.onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader align='center'>
          {translate(TranslationKeys.tryToSay)} "
          {translate(TranslationKeys.commandSampleThree)}"
        </ModalHeader>
        <ModalBody>
          <Container
            display='flex'
            alignItems='center'
            justifyContent='center'
            marginTop='20px'
            flexDir='column'
          >
            {!isEndOfUtteranceDetected ? (
              <ChakraAnimationBox
                layerStyle='ActiveRecordingAnimation'
                animate={AUDIO_MIC_ANIMATION}
                // @ts-ignore no problem in operation, although type error appears.
                transition={AUDIO_MIC_ANIMATION_TRANSITION}
              >
                <BsMicFill size={30} style={{ color: 'white' }} />
              </ChakraAnimationBox>
            ) : (
              <ChakraAnimationBox layerStyle='ActiveRecordingAnimation'>
                <BsMicFill size={30} style={{ color: 'white' }} />
              </ChakraAnimationBox>
            )}

            <Textarea
              autoFocus={false}
              overflowY='auto'
              size='md'
              height={130}
              border='none'
              top='3'
              focusBorderColor='transparent'
              value={transcribeMessage}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='red' mr={3} onClick={hfoModelDisclosure.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
