import { useMemo } from 'react';
import _ from 'lodash';

import { CallSession } from 'src/models/CallSession.model';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

const usePersonalCallSession = (): CallSession | undefined => {
  const { callSessions } = useCallSessions();
  const personalCallSession = useMemo(
    () => _.head(callSessions),
    [callSessions],
  );

  return personalCallSession;
};

export default usePersonalCallSession;
