import { Box, chakra } from '@chakra-ui/react';
import { FC } from 'react';
import './voice-animation.css';

const elements = { green: 'blue', red: 'blue', blue: 'blue', yellow: 'blue' };

interface VoiceAnimationProps {
  isAnimating: boolean;
}

export const VoiceAnimation: FC<VoiceAnimationProps> = ({ isAnimating }) => {
  return (
    <chakra.span>
      <Box
        animation={isAnimating ? 'dot-elastic 1s infinite linear' : 'none'}
        className='dot-elastic'
        _before={{
          animation: isAnimating
            ? 'dot-elastic-before 1s infinite linear;'
            : 'none',
        }}
        _after={{
          animation: isAnimating
            ? 'dot-elastic-after 1s infinite linear;'
            : 'none',
        }}
      ></Box>
    </chakra.span>
  );
};
