import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallSessionExistingGroupCallConnected,
  unsubscribeCallSessionExistingGroupCallConnected,
} from 'src/apis/socket.io/call-sessions.socket.io';

import { useCallSessionsUpdates } from '../../useCallSessions';

const useCallSessionCreatedOnExistingCallUpdate = () => {
  const { addOne } = useCallSessionsUpdates();

  const handleExistingGroupCall = useCallback<CallSessionHandler>(addOne, [
    addOne,
  ]);

  useEffect(() => {
    subscribeCallSessionExistingGroupCallConnected(handleExistingGroupCall);

    return () => {
      unsubscribeCallSessionExistingGroupCallConnected(handleExistingGroupCall);
    };
  }, [handleExistingGroupCall]);
};

export default useCallSessionCreatedOnExistingCallUpdate;
