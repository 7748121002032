import { useMemo } from 'react';
import { QueryKey, useQuery } from 'react-query';

import { getAllSessionsForCall } from 'src/apis/call-sessions.api';
import { CallSession } from 'src/models/CallSession.model';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

import useCallParams from './useCallParams';

const getCallQueryKey = (callId: CallSession['CallId']): QueryKey => [
  'getAllCallSessionsForCall',
  callId,
];

export const useCallSessionsUpdates = () => {
  const { callId } = useCallParams();

  return useArrayDataQuery<CallSession>({
    queryKey: getCallQueryKey(callId),
  });
};

const useCallSessions = () => {
  const { callId } = useCallParams();
  const { data, refetch } = useQuery(getCallQueryKey(callId), () =>
    getAllSessionsForCall(callId),
  );

  const callSessions = useMemo(() => data || [], [data]);

  return { callSessions, refetch };
};

export default useCallSessions;
