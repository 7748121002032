import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallMissed,
  unsubscribeCallMissed,
} from 'src/apis/socket.io/call-sessions.socket.io';

import { useCallSessionsUpdates } from '../../useCallSessions';

const useCallMissedUpdate = () => {
  const { updatePartial } = useCallSessionsUpdates();

  const handleCallMissed = useCallback<CallSessionHandler>(updatePartial, [
    updatePartial,
  ]);

  useEffect(() => {
    subscribeCallMissed(handleCallMissed);

    return () => {
      unsubscribeCallMissed(handleCallMissed);
    };
  }, [handleCallMissed]);
};

export default useCallMissedUpdate;
