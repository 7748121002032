import _ from 'lodash';

import { CallSession } from 'src/models/CallSession.model';
import { PersonalCalls } from 'src/models/PersonalCall';
import {
  getDateDiffSecondsFromNow,
  secondsToDuration,
} from 'src/pages/peer-call/components/header/components/call-duration/utils/call-duration.utils';
import { ColorShaderMap } from 'src/utils';

export const getSortedCallHistory = (
  personalCalls: PersonalCalls[],
  callerId: number,
  receiverId: number,
) => {
  const checkIfUserIsInvolved = (session: CallSession) => {
    return (
      (session.ReceiverId === receiverId && session.CallerId === callerId) ||
      (session.ReceiverId === callerId && session.CallerId === receiverId)
    );
  };

  return _.chain(personalCalls)
    .orderBy(({ createdAt }) => createdAt, 'desc')
    .filter(({ session }) => checkIfUserIsInvolved(session))
    .slice(1)
    .value();
};

export const getDuration = (joinedAt: Date, disconnectedAt: Date) => {
  if (joinedAt && disconnectedAt) {
    const userJoinedAt = getDateDiffSecondsFromNow(new Date(joinedAt));
    const userDisconnectedAt = getDateDiffSecondsFromNow(
      new Date(disconnectedAt),
    );
    const durationDifference = userJoinedAt - userDisconnectedAt;
    return secondsToDuration(durationDifference);
  }
};

export const getColorForCallIcon = (joinedAt: Date | null) => {
  if (joinedAt) {
    return ColorShaderMap.green[500];
  } else if (!joinedAt) {
    return ColorShaderMap.red[500];
  } else {
    return ColorShaderMap.gray[500];
  }
};
