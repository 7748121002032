import { Avatar, Icon, List } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import {
  onNewGroupMessageThread,
  unsubscribeNewGroupMessageThread,
} from 'src/apis/socket.io/group-message-threads.socket.io';
import FloatingActionButton from 'src/components/FloatingActionButton';
import { TranslationKeys } from 'src/constants/translation-keys';
import { MessageDto } from 'src/dto/messages.dto';
import useDialog from 'src/hooks/useDialog';
import useGroupChats, {
  useGroupChatsQueryClient,
} from 'src/hooks/useGroupMessages';
import { useTranslate } from 'src/hooks/useTranslate';
import useUnreadGroupChatsRecord from 'src/hooks/useUnreadGroupChatsRecord';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';
import {
  CHAT_PAGE_ROUTE,
  kMessageThreadIdParamName,
} from 'src/routes/routeList';

import MessageItem from '../components/MessageItem';
import NewGroupChatDialog from '../components/new-group-chat-dialog';
import useNewMessageObserver from '../hooks/useNewMessageObserver';
import getLatestMessageText from '../utils/getLatestMessage.util';
import getMessageThreadDate from '../utils/getMessageThreadDate.util';
import EmptyGroupChats from './components/EmptyGroupChats';
import GroupInfoDialog from './components/GroupInfoDialog';
import { sortMessageThreads } from './utils/sort-message-threads.util';

const GroupMessagesPanel = () => {
  const { translate } = useTranslate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageThreads, groupChatsQuery] = useGroupChats();
  const { addOne: addGroupChat, updateOne: updateGroupChat } =
    useGroupChatsQueryClient();
  const [unreadGroupChatsRecord, resetUnreadForGroupChatId] =
    useUnreadGroupChatsRecord();

  const [groupToShowInfo, setGroupToShowInfo] =
    useState<GroupMessageThread['id']>();

  // Since we would be sub/unsubbing to events, we want the callback to be unique and not re-use from a shared hook
  const handleNewMessageThread = useCallback<typeof addGroupChat>(
    addGroupChat,
    [addGroupChat],
  );

  useEffect(() => {
    onNewGroupMessageThread(handleNewMessageThread);

    return () => unsubscribeNewGroupMessageThread(handleNewMessageThread);
  }, [handleNewMessageThread]);

  const handleNewMessage = useCallback(
    (message: MessageDto) => {
      _.forEach(messageThreads, thread => {
        if (thread.id === message.MessageThreadId) {
          updateGroupChat({
            ...thread,
            latestMessage: {
              ...thread.latestMessage,
              ...message,
              createdAt: new Date(message.createdAt),
              updatedAt: new Date(message.updatedAt),
              messageAt: new Date(message.messageAt),
            },
          });
        }
      });
    },
    [updateGroupChat, messageThreads],
  );

  useNewMessageObserver({ handleNewMessage });

  const history = useHistory();
  const goToChat = (messageThreadId: number) => {
    resetUnreadForGroupChatId(messageThreadId);
    history.push(
      CHAT_PAGE_ROUTE.replace(
        `:${kMessageThreadIdParamName}`,
        messageThreadId.toString(),
      ),
    );
  };

  const [isNewChatDialogOpen, openNewChatDialog, closeNewChatDialog] =
    useDialog();

  const showToast = useToast();
  const onChatCreated = useCallback(
    (chat: GroupMessageThread) => {
      closeNewChatDialog();
      showToast({
        title: `${translate(TranslationKeys.createdGroup)}: ${chat.group.name}`,
        status: 'success',
      });
      addGroupChat(chat);
      goToChat(chat.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToast],
  );

  const sortedChats = sortMessageThreads(
    messageThreads,
    id => unreadGroupChatsRecord[id],
  );

  return (
    <>
      <List spacing='4' paddingInline='0' pb='10'>
        {_.isEmpty(messageThreads) ? (
          <EmptyGroupChats />
        ) : (
          _.map(sortedChats, ({ id, group, createdAt, latestMessage }) => (
            <MessageItem
              key={id}
              title={group.name}
              subtitle={getLatestMessageText(
                latestMessage,
                translate(TranslationKeys.beginConversation),
              )}
              topRightInfo={getMessageThreadDate({
                createdAt,
                latestMessage,
              })}
              unreadMessageCount={unreadGroupChatsRecord[id]}
              avatarComponent={
                <Avatar
                  name={group.name}
                  src={group.imageUrl}
                  onClick={() => setGroupToShowInfo(id)}
                />
              }
              onClick={() => goToChat(id)}
            />
          ))
        )}
      </List>
      <FloatingActionButton
        aria-label='Create New Group Chat Button'
        icon={<Icon as={MdAdd} boxSize='5' />}
        onClick={openNewChatDialog}
        bottom='10'
        right='6'
      />
      <NewGroupChatDialog
        onChatCreated={onChatCreated}
        isOpen={isNewChatDialogOpen}
        onClose={closeNewChatDialog}
      />
      {groupToShowInfo && (
        <GroupInfoDialog
          messageThreadId={groupToShowInfo}
          isOpen={!!groupToShowInfo}
          onClose={() => setGroupToShowInfo(undefined)}
        />
      )}
    </>
  );
};

export default GroupMessagesPanel;
