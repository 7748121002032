import { Box, useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import { updateMessageThreadAcceptance } from 'src/apis/message-threads.api';
import { createTextMessage } from 'src/apis/messages.api';
import { TextAudio } from 'src/apis/socket.io/messages.socket.io';
import { NAVBAR_HEIGHT } from 'src/constants';
import { maxWidth } from 'src/constants/max-width.constant';
import { CHAT_PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER } from 'src/constants/messag.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { MessageThread } from 'src/models/MessageThread.model';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';
import { kMessageThreadIdParamName as messageThreadId } from 'src/routes/routeList';
import { getUserDisplayName } from 'src/utils/user.utils';
import ChatFooter from './components/ChatFooter';
import ChatHeader from './components/ChatHeader';
import ChatMessagesPanel from './components/ChatMessagesPanel';
import GroupMessageBox from './components/GroupMessageBox';
import { MessageThreadStatus } from './components/MessageThreadStatus';
import useMessages from './hooks/useMessages';
import useMessageThread from './hooks/useMessageThread';
import useMessageWithLimit from './hooks/useMessageWithLimit';
import useTypingObserver from './hooks/useTyping';

interface ChatPageUrlParams {
  [messageThreadId]: string;
}
const kMaxUsersTypingDisplay = 2;

export interface tfModelType {
  model: string;
  metaData: string;
  binaryFile1: string;
  binaryFile2: string;
}

const ChatPage = () => {
  const { translate } = useTranslate();
  const chatPageParams = useParams<ChatPageUrlParams>();
  const messageThreadId = +chatPageParams.messageThreadId;
  const showToast = useToast();

  const { message: msgContent, handleMessageChange: setMessage } =
    useMessageWithLimit();

  const { mutate: createMessageMutate } = useMutation(createTextMessage);

  const [textAudio, setTextAudio] = useState<TextAudio>();

  const { messageThread, updateMessageThread } =
    useMessageThread(messageThreadId);

  const { mutate: updateAcceptance } = useMutation<
    MessageThread,
    any,
    { hasAccepted: boolean }
  >(
    ['updateMessageThreadAcceptance', messageThreadId],
    ({ hasAccepted }) =>
      updateMessageThreadAcceptance(messageThreadId, hasAccepted),
    { onSuccess: updateMessageThread },
  );

  const { messages } = useMessages({ messageThreadId });
  const { usersTyping, isSomeoneTyping } = useTypingObserver();

  const onMessageSend = (text: string, isTextToSpeechEnable?: boolean) => {
    createMessageMutate(
      { messageThreadId, text, isTextToSpeechEnable },
      {
        onError: error => {
          showToast({
            title:
              translate((error as AxiosError)?.response?.data?.message) ||
              translate(TranslationKeys.failedToSendMessage),
            status: 'error',
          });
        },
      },
    );
  };

  if (!messageThread) {
    return null;
  }

  const personalMsgThread = messageThread as unknown as PersonalMessageThread;
  const hasAcceptedPersonalChat = personalMsgThread.hasAccepted;
  const hasRecipientBlocked = personalMsgThread.hasRecipientBlocked;

  const getTypingText = () => {
    if (messageThread.isGroup && !_.isEmpty(usersTyping)) {
      const countUsersTyping = usersTyping.length;
      if (countUsersTyping === 1) {
        return `${getUserDisplayName(usersTyping[0])} ${translate(
          TranslationKeys.isTyping,
        )}`;
      }

      if (countUsersTyping === 2) {
        const user1Name = getUserDisplayName(usersTyping[0]);
        const user2Name = getUserDisplayName(usersTyping[1]);

        return `${user1Name} and ${user2Name} ${translate(
          TranslationKeys.areTyping,
        )}`;
      }

      const usersTypingClipped = _.take(usersTyping, kMaxUsersTypingDisplay);
      const commaSeparatedUsers = _.join(
        _.map(usersTypingClipped, getUserDisplayName),
        ', ',
      );

      const remainingUsers = countUsersTyping - usersTypingClipped.length;
      const suffix =
        remainingUsers > 0
          ? `and ${remainingUsers} ${translate(TranslationKeys.more)} `
          : '';
      return `${commaSeparatedUsers} ${suffix} ${translate(
        TranslationKeys.areTyping,
      )}`;
    }

    return translate(TranslationKeys.typing);
  };

  return (
    <Box m='0' position='relative' key={messageThreadId}>
      <ChatHeader messageThread={messageThread} setTextAudio={setTextAudio} />
      <Box
        maxW={maxWidth}
        h={CHAT_PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER}
        mx='auto'
        mt={NAVBAR_HEIGHT}
        overflow='hidden'
        position='relative'
      >
        <ChatMessagesPanel
          hasRecipientBlocked={hasRecipientBlocked}
          messages={messages}
          MessageComponent={messageThread.isGroup ? GroupMessageBox : undefined}
          textAudio={textAudio}
          setTextAudio={setTextAudio}
          hasAccepted={hasAcceptedPersonalChat}
        />
        <MessageThreadStatus
          typing={isSomeoneTyping}
          typingText={getTypingText()}
          isGroupChat={messageThread.isGroup}
          hasAccepted={hasAcceptedPersonalChat}
          onAcceptanceChange={hasAccepted => updateAcceptance({ hasAccepted })}
          hasRecipientBlocked={hasRecipientBlocked}
        />
      </Box>
      <ChatFooter
        chatInputPanelProps={{
          onMessageSend,
          messageThreadId,
          msgContent,
          setMessage,
        }}
      />
    </Box>
  );
};
export default ChatPage;
