import { DataConnection } from 'peerjs';
import { createContext } from 'react';

interface PeerConnectionContextType {
  peerAudioStatus: Record<number, boolean>;
  peerVideoStatus: Record<number, boolean>;
  peerStreams: Record<number, MediaStream | null>;
  mutePeerStream: (id: number) => void;
  unmutePeerStream: (id: number) => void;
  dataConnections: DataConnection[];
  toggleAudio: () => void;
  toggleVideo: () => void;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  isFrontCamera: boolean;
  switchCamera: () => void;
}

export const initialPeerConnectionContextValue: PeerConnectionContextType = {
  peerAudioStatus: {},
  peerVideoStatus: {},
  peerStreams: {},
  mutePeerStream: () => {},
  unmutePeerStream: () => {},
  dataConnections: [],
  isAudioMuted: true,
  isFrontCamera: true,
  isVideoMuted: true,
  switchCamera: () => {},
  toggleAudio: () => {},
  toggleVideo: () => {},
};

export const PeerConnectionContext = createContext<PeerConnectionContextType>(
  initialPeerConnectionContextValue,
);
