import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallAccepted,
  unsubscribeCallAccepted,
} from 'src/apis/socket.io/call-sessions.socket.io';

import { useCallSessionsUpdates } from '../../useCallSessions';

const useCallAcceptedUpdate = () => {
  const { updatePartial } = useCallSessionsUpdates();

  const handleCallAccepted = useCallback<CallSessionHandler>(updatePartial, [
    updatePartial,
  ]);

  useEffect(() => {
    subscribeCallAccepted(handleCallAccepted);

    return () => {
      unsubscribeCallAccepted(handleCallAccepted);
    };
  }, [handleCallAccepted]);
};

export default useCallAcceptedUpdate;
