import { DataConnection, MediaConnection } from 'peerjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';

import { CAMERA_FACING_MODE } from 'src/constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

import {
  addTrack,
  notifyDisableMic,
  notifyDisableVideo,
  notifyEnableMic,
  notifyEnableVideo,
} from '../utils/data-connection.utils';
import {
  hasAudioTracks,
  disableAudioTracks,
  enableAudioTracks,
  hasVideoTracks,
  disableVideoTracks,
  enableVideoTracks,
} from '../utils/media-stream.utils';

export const useStreamControl = (
  localStream: MediaStream | null,
  calls: MediaConnection[],
  dataConnections: DataConnection[],
) => {
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const callsRef = useRef<MediaConnection[]>([]);
  const tempStreamRef = useRef<MediaStream[]>([]);

  const { currentUser } = useUserDetails();

  const audioConstraints: MediaStreamConstraints = {
    audio: true,
  };

  const videoConstraints: MediaStreamConstraints = useMemo(() => {
    return {
      video: {
        facingMode: isFrontCamera
          ? CAMERA_FACING_MODE.user
          : CAMERA_FACING_MODE.environment,
      },
    };
  }, [isFrontCamera]);

  const addStreamedTrack = useCallback(
    (track: MediaStreamTrack) => {
      if (localStream && callsRef.current.length) {
        localStream.addTrack(track);
        addTrack(track, localStream, callsRef.current);
      }
    },
    [localStream, callsRef.current.length],
  );

  const fetchStreamAndMerge = useCallback(
    async (constraints: MediaStreamConstraints, type: 'audio' | 'video') => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (stream && localStream) {
          tempStreamRef.current.push(stream);
          if (type === 'audio') {
            _.forEach(localStream.getAudioTracks(), track => {
              track.stop();
              localStream.removeTrack(track);
            });
            _.forEach(stream.getAudioTracks(), addStreamedTrack);
            return;
          }
          _.forEach(localStream.getVideoTracks(), track => {
            track.stop();
            localStream.removeTrack(track);
          });
          _.forEach(stream.getVideoTracks(), addStreamedTrack);
        }
      } catch (error) {
        console.error('Error fetching user media', error);
      }
    },
    [localStream],
  );

  const toggleAudio = useCallback(() => {
    if (!currentUser) return;
    setIsAudioMuted(prevAudioMuted => {
      const willAudioBeMuted = !prevAudioMuted;

      if (localStream) {
        if (hasAudioTracks(localStream)) {
          if (willAudioBeMuted) {
            notifyDisableMic(dataConnections, currentUser.id);
            disableAudioTracks(localStream);
          } else {
            notifyEnableMic(dataConnections, currentUser.id);
            enableAudioTracks(localStream);
          }
        }
      } else {
        fetchStreamAndMerge(audioConstraints, 'audio');
      }

      return willAudioBeMuted;
    });
  }, [localStream, currentUser, dataConnections]);

  const toggleVideo = useCallback(() => {
    if (!currentUser) return;
    setIsVideoMuted(prevVideoMuted => {
      const willVideoBeMuted = !prevVideoMuted;

      if (localStream) {
        if (hasVideoTracks(localStream)) {
          if (willVideoBeMuted) {
            notifyDisableVideo(dataConnections, currentUser.id);
            disableVideoTracks(localStream);
          } else {
            notifyEnableVideo(dataConnections, currentUser.id);
            enableVideoTracks(localStream);
          }
        } else {
          fetchStreamAndMerge(videoConstraints, 'video');
        }
      } else {
        fetchStreamAndMerge(videoConstraints, 'video');
      }

      return willVideoBeMuted;
    });
  }, [localStream, currentUser, dataConnections, videoConstraints]);

  const switchCamera = useCallback(() => {
    if (localStream) {
      setIsFrontCamera(prevIsFrontCamera => !prevIsFrontCamera);
    }
  }, [localStream]);

  const streamCleanup = () => {
    _.forEach(tempStreamRef.current, stream => {
      _.forEach(stream.getTracks(), track => {
        track.stop();
      });
    });
  };

  useEffect(() => {
    fetchStreamAndMerge(videoConstraints, 'video');
  }, [videoConstraints]);

  useEffect(() => {
    if (calls.length) {
      callsRef.current = calls;
    }
  }, [calls]);

  return {
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    isFrontCamera,
    switchCamera,
    streamCleanup,
  };
};
