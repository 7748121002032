import { Center, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { CALL_HEADER_HEIGHT } from 'src/constants/group-call.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { CallSession } from 'src/models/CallSession.model';
import CallDuration from 'src/pages/peer-call/components/header/components/call-duration';
import useCall from 'src/pages/peer-call/hooks/useCall';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

const GroupCallHeader = () => {
  const { call } = useCall();
  const { callSessions } = useCallSessions();
  const { currentUser } = useUserDetails();
  const [isTimerStarted, setIsTimerStarted] = useState<boolean>(true);
  const [session, setSession] = useState<CallSession>();

  const AllCallSessionOfHostUser = _.map(callSessions, session => {
    if (call?.HostUserId === session?.CallerId) {
      return session;
    }
  });

  const callSessionWithHost = _.head(AllCallSessionOfHostUser);

  const isCurrentUserHost = currentUser?.id === call?.HostUserId;

  useEffect(() => {
    if (callSessions) {
      const findSessionWithJoinedAt = callSessions.find(session => {
        if (session && session.joinedAt) {
          return session;
        }
        return undefined;
      });
      setSession(findSessionWithJoinedAt);
      setIsTimerStarted(false);
    }
  }, [callSessions, isTimerStarted]);

  return (
    <Center
      bgColor='blue.500'
      h={CALL_HEADER_HEIGHT}
      color='white'
      alignContent='center'
      flexDirection='column'
    >
      <VStack>
        <Text fontSize='x-large' fontWeight='bold'>
          {call?.messageGroup?.name}
        </Text>

        {/* if the current user is host of the call then his call duration will be visible as soon as any of user that are in the group call is pick up the call */}
        {isCurrentUserHost && session && <CallDuration callSession={session} />}

        {/* if the current user is receiver then the call duration is visible as soon as he pickup the call */}
        {!isCurrentUserHost && callSessionWithHost && (
          <CallDuration callSession={callSessionWithHost} />
        )}
      </VStack>
    </Center>
  );
};
export default GroupCallHeader;
