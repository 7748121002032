import { createContext } from 'react';

export interface UserLocation {
  latitude: number;
  longitude: number;
  accuracy: number;
}

export interface UserLocationProps {
  locationStream?: UserLocation;
  getIsLocationPermissionGranted: () => Promise<boolean>;
}

export const UserLocationContext = createContext<UserLocationProps>(null!);
