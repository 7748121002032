import _ from 'lodash';

import { axiosAsrInstance, axiosInstance } from 'src/config';
import { SpeechRecognitionApiKeys } from 'src/constants/speech-recognition.constant';
import { CreateRecordingDto } from 'src/dto/create-recording.dto';

type SendCommandToNlpServerDto = {
  UserId: number;
  language: string | undefined;
  text: string;
  accessToken: string | null;
  MessageThreadId?: number;
  longitude?: string;
  latitude?: string;
};

export const createRecording = async (newRecording: CreateRecordingDto) => {
  const formData = new FormData();
  formData.append(
    SpeechRecognitionApiKeys.messageThreadId,
    _.toString(newRecording.MessageThreadId),
  );
  formData.append(
    SpeechRecognitionApiKeys.userId,
    _.toString(newRecording.UserId),
  );
  formData.append(
    SpeechRecognitionApiKeys.timestamp,
    _.toString(newRecording.timestamp),
  );
  formData.append(
    SpeechRecognitionApiKeys.accessToken,
    _.toString(newRecording.token),
  );
  formData.append(
    SpeechRecognitionApiKeys.recordingStop,
    _.toString(newRecording.recStop),
  );
  formData.append(
    SpeechRecognitionApiKeys.language,
    _.toString(newRecording.language),
  );
  if (newRecording.file) {
    formData.append(SpeechRecognitionApiKeys.file, newRecording.file);
  }
  const res = await axiosAsrInstance.post('/audio-stream', formData);

  return res.data;
};

export const getTensorflowModelUrl = async () => {
  const res = await axiosInstance.get('/speech-recognition/tfjs-url');
  return res.data;
};

export const sendCommandToNlpServer = async (
  payload: SendCommandToNlpServerDto,
) => {
  const res = await axiosInstance.post(
    '/speech-recognition/send-command-to-nlp-server',
    payload,
  );

  return res.data;
};
