import { IconButton } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Input } from '@chakra-ui/input';
import { Box, BoxProps, HStack } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { BaseEmoji, NimblePicker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import data from 'emoji-mart/data/google.json';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { BiMicrophone } from 'react-icons/bi';
import { FaRegSmile } from 'react-icons/fa';
import { IoAttachOutline, IoCameraOutline } from 'react-icons/io5';
import { MdSend } from 'react-icons/md';
import { useMutation } from 'react-query';

import { postTextMessage } from 'src/apis/messages.api';
import { stoppedTyping, typing } from 'src/apis/socket.io/messages.socket.io';
import { typingTimeoutDelayTimeInMilliSeconds } from 'src/constants/chat.constant';
import { kMaxFileSizeMegabytes } from 'src/constants/files.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { ValueCallback } from 'src/types/common.type';
import { megabytes } from 'src/utils/data.utils';

import CameraDialog from './CameraDialog';
import MediaPreviewDialog from './MediaPreviewDialog';
import RecordAudioDialog from './RecordAudioDialog';

export interface ChatInputPanelProps extends BoxProps {
  onMessageSend: ValueCallback<string, boolean>;
  messageThreadId: number;
  msgContent: string;
  setMessage: (changedMessage: string, concat?: boolean) => void;
}

// Using `leading: true`, we tell debounce to let the first (leading) function call be executed
// and then start debouncing. So that we don't wait for n seconds till user starts typing to emit the event
// we emit as soon as user starts typing but then start debouncing the change events
const emitTyping = _.debounce(typing, 1000, { leading: true });

const ChatInputPanel: React.FC<ChatInputPanelProps> = ({
  onMessageSend,
  messageThreadId,
  msgContent,
  setMessage,
  ...props
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();
  const { isTextToSpeechEnable } = useHandsFreeOperationSetting();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showMediaPreviewDialog, setShowMediaPreviewDialog] = useState(false);
  const [mediaToPreview, setMediaToPreview] = useState<
    Blob[] | File[] | FileList
  >([]);
  const [showCameraDialog, setShowCameraDialog] = useState(false);

  const { mutate: textMessageMutate } = useMutation(postTextMessage);
  const typingTimeout = useRef<NodeJS.Timeout>();

  const showToast = useToast();

  useEffect(() => {
    if (mediaToPreview.length > 0) {
      setShowMediaPreviewDialog(true);
    } else {
      setShowMediaPreviewDialog(false);
    }
  }, [mediaToPreview]);

  const handleKeyPress: React.KeyboardEventHandler<
    HTMLInputElement
  > = event => {
    if (msgContent.trim().length === 0) {
      return;
    }

    if (event.key === 'Enter') {
      onMessageSend(msgContent);
      textMessageMutate({ messageThreadId, text: msgContent });
      setMessage('');
    }
  };

  const handleOnTextInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    setMessage(e.currentTarget.value);

    if (currentUser?.id) {
      const typingPayload = {
        messageThreadId,
        user: _.pick(currentUser, ['id', 'firstName', 'lastName']),
      };
      emitTyping(typingPayload);
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current);
      }
      typingTimeout.current = setTimeout(
        () => stoppedTyping(typingPayload),
        typingTimeoutDelayTimeInMilliSeconds,
      );
    }
  };

  const handleEmojiIconClick = () =>
    setShowEmojiPicker(prevShowEmojiPicker => !prevShowEmojiPicker);

  const handleOnEmojiSelect = (emoji: BaseEmoji) => {
    setMessage(`${msgContent}${emoji.native}`);
  };

  const handleOnFileAttachIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleOnFileAttachChange = () => {
    if (fileInputRef.current) {
      const files = fileInputRef.current.files;

      const validFiles = _.filter(files, file => {
        if (file.size > megabytes(kMaxFileSizeMegabytes)) {
          return false;
        }

        return true;
      });
      const totalInvalidFiles = (files?.length || 0) - validFiles.length;

      if (totalInvalidFiles > 0) {
        showToast({
          title: `${totalInvalidFiles} ${translate(
            TranslationKeys.filesTooLargeNotAttached,
          )}`,
          description: `${translate(
            TranslationKeys.fileSizeLimit,
          )} ${kMaxFileSizeMegabytes} MB`,
          status: 'error',
          duration: 7000,
          isClosable: true,
        });
      }

      if (validFiles.length > 0) {
        setMediaToPreview(validFiles);
      }
    }
  };

  const handleOnCameraBtnClick = () => setShowCameraDialog(true);

  const handleOnImageCapture: BlobCallback = blob => {
    if (blob) {
      setShowCameraDialog(false);
      setMediaToPreview([blob]);
    }
  };

  const closeMediaPreview = () => {
    setMediaToPreview([]);
    setShowMediaPreviewDialog(false);
  };

  const onSend = async () => {
    if (msgContent.trim().length === 0) {
      return;
    }
    onMessageSend(msgContent, isTextToSpeechEnable);

    textMessageMutate({ messageThreadId, text: msgContent });

    setMessage('');
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Box
        borderColor='gray'
        borderWidth='1px'
        borderRadius='8'
        w='full'
        p='2'
        minW='full'
        {...props}
      >
        <HStack>
          <IconButton
            icon={<Icon as={FaRegSmile} boxSize='6' />}
            aria-label='emote picker'
            variant='ghost'
            size='6'
            p='0'
            onClick={handleEmojiIconClick}
          />
          <Input
            onKeyPress={handleKeyPress}
            onChange={handleOnTextInputChange}
            value={msgContent}
            h='full'
            pl='1'
            variant='unstyled'
            placeholder={translate(TranslationKeys.typeSomething)}
            flexGrow={2}
          />

          <Input
            onChange={handleOnFileAttachChange}
            ref={fileInputRef}
            type='file'
            display='none'
            multiple
          />
          <IconButton
            icon={<Icon as={BiMicrophone} boxSize='8' />}
            aria-label='start/stop recording audio'
            variant='ghost'
            size='8'
            p='0'
            onClick={onOpen}
          />
          <IconButton
            icon={<Icon as={IoAttachOutline} boxSize='8' />}
            aria-label='multiple file picker'
            variant='ghost'
            size='8'
            p='0'
            onClick={handleOnFileAttachIconClick}
          />
          <IconButton
            icon={<Icon as={IoCameraOutline} boxSize='8' />}
            aria-label='camera icon button'
            variant='ghost'
            size='8'
            p='0'
            onClick={handleOnCameraBtnClick}
          />
          {!_.isEmpty(msgContent) && (
            <IconButton
              transitionProperty='height'
              transitionDuration='3s'
              transitionTimingFunction='ease-in'
              variant='ghost'
              icon={<Icon as={MdSend} boxSize='6' />}
              aria-label='send chat button'
              onClick={onSend}
            />
          )}
        </HStack>
      </Box>

      <Box w='full' display={showEmojiPicker ? 'block' : 'none'}>
        <NimblePicker
          set='google'
          data={data}
          onSelect={handleOnEmojiSelect}
          showPreview={false}
          showSkinTones={false}
          enableFrequentEmojiSort
          title=''
          emoji=''
          autoFocus
          style={{ width: '100%' }}
        />
      </Box>

      {isOpen && currentUser && (
        <RecordAudioDialog
          isOpen={isOpen}
          onClose={onClose}
          onMessageSend={onMessageSend}
          messageThreadId={messageThreadId}
        />
      )}

      {showMediaPreviewDialog && mediaToPreview.length > 0 && (
        <MediaPreviewDialog
          messageThreadId={messageThreadId}
          media={mediaToPreview}
          isOpen={showMediaPreviewDialog}
          onClose={closeMediaPreview}
        />
      )}

      {showCameraDialog && (
        <CameraDialog
          isOpen
          onClose={() => setShowCameraDialog(false)}
          onImageCapture={handleOnImageCapture}
        />
      )}
    </>
  );
};

export default ChatInputPanel;
