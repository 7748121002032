import { Box, Center } from '@chakra-ui/react';
import _ from 'lodash';

import { CALL_PAGE_HEIGHT } from 'src/constants/group-call.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { CallType } from 'src/types/call.type';

import { usePeerConnection } from '../../context/usePeerConnection';
import useCall from '../../hooks/useCall';
import useCallSessions from '../../hooks/useCallSessions';
import { getOtherUser } from '../../utils/call-session.utils';
import { CallMicStatus } from './components/CallMicStatus';
import CallUserImage from './components/CallUserImage';
import EndCallButton from './components/EndCallButton';
import PeerVideo from './components/PeerVideo';

interface CallPageBodyProps {}

const CallPageBody: React.FC<CallPageBodyProps> = () => {
  const { currentUser } = useUserDetails();
  const { call } = useCall();

  const { callSessions } = useCallSessions();
  const session = _.head(callSessions);
  const otherUser =
    session && currentUser && getOtherUser(session, currentUser.id);

  const {
    peerStreams,
    peerVideoStatus,
    peerAudioStatus: peerMicStatus,
  } = usePeerConnection();

  const isGroupCall = call?.isGroupCall;
  const videoStream = otherUser && peerStreams[otherUser.id];
  const isPeerVideoEnabled = otherUser && peerVideoStatus[otherUser.id];
  const isPeerMicEnabled = otherUser && peerMicStatus[otherUser.id];

  return (
    <Box h={CALL_PAGE_HEIGHT} w='full' position='relative'>
      {videoStream ? (
        <PeerVideo
          stream={videoStream}
          user={otherUser}
          peerVideoStatus={isPeerVideoEnabled}
        />
      ) : (
        <CallUserImage receiver={otherUser} />
      )}
      <CallMicStatus
        isPeerMicEnabled={isPeerMicEnabled}
        callType={CallType.Personal}
      />
      {!isGroupCall && (
        <Center position='absolute' w='full' bottom='4rem'>
          <EndCallButton />
        </Center>
      )}
    </Box>
  );
};

export default CallPageBody;
