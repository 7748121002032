import { Center, Text, useBreakpointValue } from '@chakra-ui/react';

import UserAvatar from 'src/components/UserAvatar';
import { ONE_MEMBER_IN_GROUP as ONE_JOINED_CALL_SESSION } from 'src/constants/group-call.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { CallSession } from 'src/models/CallSession.model';
import useCall from 'src/pages/peer-call/hooks/useCall';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

interface CallUserImageProps {
  receiver: CallSession['receiver'];
  joinedCall?: Date | null;
  isCurrentUser?: boolean;
}

const CallUserImage: React.FC<CallUserImageProps> = ({
  receiver,
  joinedCall,
  isCurrentUser,
}) => {
  const { translate } = useTranslate();
  const { callSessions } = useCallSessions();
  const { call } = useCall();
  const userAvatarSize = useBreakpointValue({ base: 'lg', sm: 'xl' });

  if (call && !call.isGroupCall) {
    return (
      <Center h='full' w='full' bgColor='gray.700'>
        <UserAvatar user={receiver} size='xl' fontSize='44' />
      </Center>
    );
  }

  const getConnectingStatus = () => {
    if (!isCurrentUser) {
      if (callSessions.length !== ONE_JOINED_CALL_SESSION && !joinedCall) {
        return <Text> {translate(TranslationKeys.connecting)}</Text>;
      }
      return null;
    }
  };

  return (
    <Center
      h='full'
      w='full'
      bgColor='gray.700'
      flexDir='column'
      gridGap='2'
      position='relative'
    >
      <UserAvatar user={receiver} size={userAvatarSize} />
      {getConnectingStatus()}
    </Center>
  );
};

export default CallUserImage;
