import Routes from 'src/routes';
import useCallNotifications from '../hooks/call-notifications/useCallNotifications';
import UserRoomWrapper from './UserRoomWrapper';
import { UserSOSAlertWrapper } from './UserSOSAlertWrapper';

export const AppContent = () => {
  useCallNotifications();

  return (
    <UserRoomWrapper>
      <UserSOSAlertWrapper>
        <Routes />
      </UserSOSAlertWrapper>
    </UserRoomWrapper>
  );
};
