import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallUnreachable,
  unsubscribeCallUnreachable,
} from 'src/apis/socket.io/call-sessions.socket.io';

import { useCallSessionsUpdates } from '../../useCallSessions';

const useCallUnreachableUpdate = () => {
  const { updatePartial } = useCallSessionsUpdates();

  const handleCallUnreachable = useCallback<CallSessionHandler>(updatePartial, [
    updatePartial,
  ]);

  useEffect(() => {
    subscribeCallUnreachable(handleCallUnreachable);

    return () => {
      unsubscribeCallUnreachable(handleCallUnreachable);
    };
  }, [handleCallUnreachable]);
};

export default useCallUnreachableUpdate;
