import * as _ from 'lodash';
import { useCallback, useEffect } from 'react';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PeerConnectionContext } from 'src/pages/peer-call/context/peer-connection.context';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

import { useUserMedia } from '../../components/footer/context/user-media/user-media.context';
import usePeerCall from '../../hooks/usePeerCall';
import { useStreamControl } from '../../hooks/useStreamControl';

const PeerConnectionProvider: React.FC = ({ children }) => {
  const {
    callPeer,
    createPeer,
    handlePeer,
    callCleanup,
    calls,
    peer,
    peerStreams,
    dataConnections,
    peerAudioStatus,
    peerVideoStatus,
  } = usePeerCall();
  const { localStream } = useUserMedia();
  const { callSessions } = useCallSessions();
  const { currentUser } = useUserDetails();
  const {
    isAudioMuted,
    isFrontCamera,
    isVideoMuted,
    switchCamera,
    toggleAudio,
    toggleVideo,
    streamCleanup,
  } = useStreamControl(localStream, calls, dataConnections);

  useEffect(() => {
    if (!peer && currentUser && localStream) {
      createPeer(currentUser.id.toString());
      handlePeer(localStream);
    }

    return () => {
      callCleanup();
      streamCleanup();
      if (peer) peer.destroy();
    };
  }, [currentUser, localStream, peer]);

  useEffect(() => {
    callPeerViaCallSessions();
  }, [callSessions]);

  const callPeerViaCallSessions = useCallback(() => {
    _.forEach(callSessions, session => {
      if (
        session.joinedAt &&
        localStream &&
        currentUser &&
        session.ReceiverId !== currentUser.id
      ) {
        callPeer(session.ReceiverId.toString(), localStream);
      }
    });
  }, [callSessions, currentUser, localStream]);

  const mutePeerStream = useCallback(
    (callSessionId: keyof Record<number, MediaStream>) => {
      const peerStream = peerStreams[callSessionId];
      if (!peerStream) {
        return;
      }

      _.forEach(peerStream.getAudioTracks(), track => (track.enabled = false));
    },
    [peerStreams],
  );

  const unmutePeerStream = useCallback(
    (callSessionId: keyof Record<number, MediaStream>) => {
      const peerStream = peerStreams[callSessionId];
      if (!peerStream) {
        return;
      }

      _.forEach(peerStream.getAudioTracks(), track => (track.enabled = true));
    },
    [peerStreams],
  );

  const contextValue = {
    peerAudioStatus,
    peerVideoStatus,
    peerStreams,
    mutePeerStream,
    unmutePeerStream,
    dataConnections,
    isAudioMuted,
    isFrontCamera,
    isVideoMuted,
    switchCamera,
    toggleAudio,
    toggleVideo,
  };

  return (
    <PeerConnectionContext.Provider value={contextValue}>
      {children}
    </PeerConnectionContext.Provider>
  );
};

export default PeerConnectionProvider;
