import Peer, { DataConnection, MediaConnection } from 'peerjs';
import { useRef, useState } from 'react';
import * as _ from 'lodash';

import usePeerDataConnection from './usePeerDataConnection';
import { DataChannelsEventsMessage } from '../components/footer/context/data-channels.constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

const usePeerCall = () => {
  const [dataConnections, setDataConnections] = useState<DataConnection[]>([]);
  const [calls, setCalls] = useState<MediaConnection[]>([]);
  const [peerStreams, setPeerStreams] = useState<
    Record<number, MediaStream | null>
  >({});
  const { handleDataChannelMessage, peerAudioStatus, peerVideoStatus } =
    usePeerDataConnection();

  const peerIDRef = useRef<string>();
  const peerInstanceRef = useRef<Peer>();
  const isPeerConnectedRef = useRef(false);

  const { currentUser } = useUserDetails();

  const createPeer = (selfPeerId: string) => {
    peerInstanceRef.current = new Peer(selfPeerId, {
      host: window.location.hostname,
      port: +process.env.REACT_APP_PEERJS_PORT!,
      path: process.env.REACT_APP_PEERJS_PATH,
    });
  };

  const handlePeer = (localStream: MediaStream | null) => {
    const peer = peerInstanceRef.current;

    if (!peer) {
      return;
    }

    peer.on('open', (id: string) => {
      peerIDRef.current = id;
      isPeerConnectedRef.current = true;
    });

    peer.on('call', call => {
      setCalls(prevCalls => [...prevCalls, call]);

      if (localStream) {
        call.answer(localStream);

        call.on('stream', remoteStream => {
          setPeerStreams(prevStreams => ({
            ...prevStreams,
            [parseInt(call.peer)]: remoteStream,
          }));
        });

        const dataConnection = peer.connect(call.peer, {
          serialization: 'json',
        });

        dataConnection.on('open', () => {
          setDataConnections(prev => [...prev, dataConnection]);
          sendInitialMessageForMic(dataConnection);
        });

        handleDataChannelMessage(dataConnection);
      }
    });

    peer.on('connection', connection => {
      setDataConnections(prevConnections => [...prevConnections, connection]);
      handleDataChannelMessage(connection);
    });
  };

  const callPeer = (otherPeerId: string, localStream: MediaStream | null) => {
    const peer = peerInstanceRef.current;
    const isConnected = isPeerConnectedRef.current;

    if (
      localStream &&
      otherPeerId &&
      peer &&
      isConnected &&
      !peerStreams[parseInt(otherPeerId)]
    ) {
      const call = peer.call(otherPeerId, localStream);

      setCalls(prevCalls => [...prevCalls, call]);

      call.on('stream', remoteStream => {
        setPeerStreams(prevStreams => ({
          ...prevStreams,
          [parseInt(otherPeerId)]: remoteStream,
        }));
      });

      const dataConnection = peer.connect(otherPeerId, {
        serialization: 'json',
      });
      dataConnection.on('open', () => {
        setDataConnections(prev => [...prev, dataConnection]);
        sendInitialMessageForMic(dataConnection);
      });
      handleDataChannelMessage(dataConnection);
    }
  };

  const callCleanup = () => {
    _.forEach(calls, call => {
      call.close();
    });
  };

  const sendInitialMessageForMic = (dataConnection: DataConnection) => {
    dataConnection.send({
      message: DataChannelsEventsMessage.enableMic,
      senderId: currentUser?.id,
    });
  };

  return {
    callPeer,
    createPeer,
    handlePeer,
    callCleanup,
    dataConnections,
    calls,
    peerStreams,
    peer: peerInstanceRef.current,
    peerAudioStatus,
    peerVideoStatus,
  };
};

export default usePeerCall;
