export const MAXIMUM_MEMBERS_IN_GROUP_CALL = 5;
export const NO_MEMBERS_IN_GROUP = 0;
export const ONE_MEMBER_IN_GROUP = 1;

export const CALL_HEADER_HEIGHT_PX = 100;
export const CALL_HEADER_HEIGHT = `${CALL_HEADER_HEIGHT_PX}px`;

export const CALL_FOOTER_HEIGHT_PX = 80;
export const CALL_FOOTER_HEIGHT = `${CALL_FOOTER_HEIGHT_PX}px`;

export const CALL_PAGE_HEIGHT = `calc(100svh - ${
  CALL_HEADER_HEIGHT_PX + CALL_FOOTER_HEIGHT_PX
}px)`;

export const AUTO_ENABLE_MIC_TIMEOUT = 1000;
