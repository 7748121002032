import { useEffect, useRef, useState } from 'react';

import { AlertMediaVideoType } from 'src/models/Alerts.model';
import { kMaxFileSizeMegabytes } from 'src/constants/files.constants';
import { convertBytesToMegabytes } from 'src/utils';
import { CAMERA_FACING_MODE } from 'src/constants';

export const useVideoRecording = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isFrontCamera, setIsFrontCamera] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>();
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [videoUrl, setVideoUrl] = useState<string>();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>();

  const videoStream = new MediaStream();

  const getMimeType = () => {
    const { videoMp4, videoWebm } = AlertMediaVideoType;
    if (MediaRecorder.isTypeSupported(videoWebm)) {
      return videoWebm;
    } else if (MediaRecorder.isTypeSupported(videoMp4)) {
      return videoMp4;
    } else {
      throw new Error('No supported video format found');
    }
  };

  const mimeType = getMimeType();

  const getUserAudio = async (isFrontCamera: boolean) => {
    try {
      releaseCamera();
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          facingMode: isFrontCamera
            ? CAMERA_FACING_MODE.user
            : CAMERA_FACING_MODE.environment,
        },
      });

      setStream(mediaStream);
      if (MediaRecorder.isTypeSupported(mimeType)) {
        const recorder = new MediaRecorder(mediaStream, { mimeType });
        setMediaRecorder(recorder);
      }
    } catch (error) {
      console.error('Error initializing audio recording:', error);
    }
  };

  useEffect(() => {
    getUserAudio(isFrontCamera);
  }, [isFrontCamera]);

  const displayVideo = (stream: MediaStream) => {
    const video = videoRef.current;
    if (video && stream) {
      stream.getVideoTracks().forEach(track => videoStream.addTrack(track));
      video.srcObject = videoStream;
      video.play();
    }
  };

  useEffect(() => {
    if (stream) displayVideo(stream);
  }, [stream]);

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start(1000);
      mediaRecorder.ondataavailable = e => {
        setChunks(prev => [...prev, e.data]);
      };
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: mimeType });
        const videoURL = URL.createObjectURL(blob);
        setVideoUrl(videoURL);
      };
    }
  };

  const releaseCamera = () => {
    stream?.getTracks().forEach(track => track.stop());
    videoStream.getTracks().forEach(track => track.stop());
    setChunks([]);
    setMediaRecorder(null);
    setStream(null);
  };

  const videoBlob = new Blob(chunks, { type: mimeType });

  // we stop recording if the video size exceeds the maximum allowed size(200 mb)
  useEffect(() => {
    const VideoSizeMb = convertBytesToMegabytes(videoBlob.size);
    if (VideoSizeMb > kMaxFileSizeMegabytes) {
      stopRecording();
    }
  }, [videoBlob]);

  // release camera on unmount
  useEffect(() => {
    return () => releaseCamera();
  }, []);

  return {
    videoRef,
    releaseCamera,
    startRecording,
    stopRecording,
    videoUrl,
    videoBlob,
    mediaRecorder,
    setIsFrontCamera,
  };
};
