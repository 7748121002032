import { useEffect, useRef } from 'react';

import { ONE_MEMBER_IN_GROUP } from 'src/constants/group-call.constant';
import CallPageBody from 'src/pages/peer-call/components/body/CallPageBody';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';
import { getChildrenHeight } from 'src/pages/peer-call/utils/call-session.utils';

import { GroupCallGridWrapper } from './components/GroupCallGrid';
import { ReceiversStream } from './components/ReceviersStream';
import { UserOwnStream } from './components/UserOwnStream';

const GroupCallBody = () => {
  const { callSessions, refetch } = useCallSessions();
  const heightRef = useRef<HTMLDivElement>(null);

  const childrenHeight = getChildrenHeight(heightRef);

  useEffect(() => {
    if (callSessions) {
      refetch();
    }
  }, [callSessions]);

  return (
    <>
      {callSessions.length === ONE_MEMBER_IN_GROUP ? (
        <CallPageBody />
      ) : (
        <GroupCallGridWrapper heightRef={heightRef}>
          <ReceiversStream height={childrenHeight} />
          <UserOwnStream height={childrenHeight} />
        </GroupCallGridWrapper>
      )}
    </>
  );
};
export default GroupCallBody;
