import { useDisclosure } from '@chakra-ui/react';
import * as speech from '@tensorflow-models/speech-commands';
import * as tf from '@tensorflow/tfjs';
import { useEffect, useState } from 'react';

import {
  RECOGNIZER_COMMAND_CONFIG,
  RECOGNIZER_TYPE,
  WEAK_WORD,
} from 'src/hoc/constants/hfo.constants';
import {
  TensorflowModelUrl,
  useTensorflowModelUrl,
} from 'src/hooks/useTensorflowModelUrl';
import { getAccessToken } from 'src/providers/auth-store.provider';

import { useHandsFreeOperationSetting } from '../hands-free-operation-setting.provider';
import { getTfUrlPath } from '../utils/get-tf-url-path';

export const useWakeWordDetection = () => {
  const hfoModelDisclosure = useDisclosure();
  const accessToken = getAccessToken();
  const { tensorflowModelUrl } = useTensorflowModelUrl();
  const { isHfoEnable } = useHandsFreeOperationSetting();

  const [isListening, setIsListening] = useState<boolean>(true);
  const [model, setModel] = useState<speech.SpeechCommandRecognizer>();

  // get the permission states of the microphone
  const permissionState = async () => {
    try {
      const permissionStateFromBrowser = await navigator.permissions.query({
        name: 'microphone',
      });
      return permissionStateFromBrowser;
    } catch (err) {
      console.log('Unable to check permission in browser', err);
    }
  };

  // initialize and set the recognizer model
  const initializeModel = async (tfUrl: TensorflowModelUrl) => {
    const tfUrlModelPath = getTfUrlPath(tfUrl.model);
    const tfUrlMetaDataPath = getTfUrlPath(tfUrl.metaData);
    const recognizer = speech.create(
      RECOGNIZER_TYPE,
      undefined,
      tfUrlModelPath,
      tfUrlMetaDataPath,
    );

    await recognizer.ensureModelLoaded();
    setModel(recognizer);
  };

  const processResult = (
    scores: Float32Array | Float32Array[],
    labels: string[],
  ) => {
    const scoreArr = Object.values(scores);

    const ArrScore = scoreArr.map((s, i) => ({
      score: s,
      word: labels[i],
    }));
    // TODO: unable to identify the type, so using any for now
    ArrScore.sort((s1: any, s2: any) => s2.score - s1.score);
    return ArrScore;
  };

  const detectWakeWord = async (
    ArrScore: {
      score: Float32Array | Float32Array[];
      word: string;
    }[],
    model: speech.SpeechCommandRecognizer,
  ) => {
    const isMatchWord = ArrScore[0].word === WEAK_WORD;

    if (isMatchWord && model && isListening) {
      await stopListening(true, model);
    }
  };

  const recognizeCommands = (
    labels: string[],
    model: speech.SpeechCommandRecognizer,
  ) => {
    if (model && isListening) {
      model.listen(
        async ({
          scores,
        }: {
          scores: Float32Array | Float32Array[];
        }): Promise<void> => {
          const arrScore = processResult(scores, labels);
          detectWakeWord(arrScore, model);
        },
        { ...RECOGNIZER_COMMAND_CONFIG },
      );
    }
  };

  const stopListening = async (
    openDialog: boolean,
    model: speech.SpeechCommandRecognizer,
  ) => {
    if (model.isListening()) {
      await model.stopListening();
      setIsListening(false);
      if (openDialog) {
        hfoModelDisclosure.onOpen();
      }
    }
  };

  const loadModel = async (tensorflowModelUrl: TensorflowModelUrl) => {
    const micPermissionState = await permissionState();
    await initializeModel(tensorflowModelUrl);

    if (
      micPermissionState &&
      micPermissionState.state === 'granted' &&
      tensorflowModelUrl
    ) {
      tf.getBackend();
    }
  };

  useEffect(() => {
    if (model) {
      const extractedLabels = model.wordLabels();
      recognizeCommands(extractedLabels, model);
    }
  }, [model]);

  useEffect(() => {
    if (model && (!isListening || !isHfoEnable)) {
      if (model.isListening()) {
        model.stopListening();
      }
      setModel(undefined);
    }

    if (
      isListening &&
      isHfoEnable &&
      tensorflowModelUrl &&
      accessToken &&
      !model
    ) {
      loadModel(tensorflowModelUrl);
    }
  }, [isListening, model, isHfoEnable, tensorflowModelUrl, model]);

  return { hfoModelDisclosure, setIsListening };
};
