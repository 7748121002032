import { Center, Flex } from '@chakra-ui/react';

import { CameraSwitchIcon } from 'src/components/Common/CameraSwitchIcon';
import { CALL_FOOTER_HEIGHT } from 'src/constants/group-call.constant';
import { maxWidth } from 'src/constants/max-width.constant';
import { usePeerConnection } from '../../context/usePeerConnection';

import usePersonalCallSession from '../../hooks/usePersonalCallSession';
import MicrophoneButton from './components/MicrophoneButton';
import SpeakerButton from './components/SpeakerButton';
import UserSelfVideo from './components/UserSelfVideo';
import VideoButton from './components/VideoButton';

interface CallPageFooterProps {}

const CallPageFooter: React.FC<CallPageFooterProps> = () => {
  return (
    <Center h={CALL_FOOTER_HEIGHT} w='full' bottom='0'>
      <Center
        bgColor='blue.500'
        w='full'
        h='full'
        maxW={maxWidth}
        position='relative'
      >
        <CallPageFooterButtons />
        <UserSelfVideo />
      </Center>
    </Center>
  );
};

const CallPageFooterButtons: React.FC = () => {
  const personalCallSession = usePersonalCallSession();
  const { isVideoMuted, switchCamera } = usePeerConnection();

  return (
    <Flex justifyContent='space-around' w='full'>
      <SpeakerButton isSpeakerDisabled={!personalCallSession?.joinedAt} />
      <VideoButton isVideoDisabled={!personalCallSession?.joinedAt} />
      <MicrophoneButton isMicDisabled={!personalCallSession?.joinedAt} />
      <CameraSwitchIcon
        switchCamera={switchCamera}
        iconFill='white'
        isDisabled={isVideoMuted}
        _hover={{ color: 'lightBlue' }}
      />
    </Flex>
  );
};

export default CallPageFooter;
