import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { createTextMessage, postTextMessage } from 'src/apis/messages.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import useNewCall from 'src/hooks/useNewCall';
import { useTranslate } from 'src/hooks/useTranslate';

import { useHandsFreeOperation } from '../hands-free-operation.provider';

export enum CommandType {
  call = 'call',
  message = 'message',
  sos = 'sos',
}

export type NLPCommandExecutionResponse = {
  command: string;
  recipientUserId?: number;
  messageThreadId?: number;
  message?: string;
  alertId?: number;
};

export const useHFOCommandExecution = () => {
  const { translate } = useTranslate();
  const showToast = useToast();
  const { makePersonalCall } = useNewCall();
  const { setAlertId, onOpenAlertInformationProvider } =
    useHandsFreeOperation();

  const { mutate: postMessageMutate } = useMutation(postTextMessage);
  const { mutate: createMessageMutate } = useMutation(createTextMessage);

  const executeCommand = (response: NLPCommandExecutionResponse) => {
    if (response.command === CommandType.call && response.recipientUserId) {
      makePersonalCall({ ReceiverId: response.recipientUserId });
      return;
    }

    if (
      response.command === CommandType.message &&
      response.messageThreadId &&
      response.message
    ) {
      createMessageMutate({
        messageThreadId: response.messageThreadId,
        text: response.message,
      });
      postMessageMutate({
        messageThreadId: response.messageThreadId,
        text: response.message,
      });
      return;
    }

    if (response.command === CommandType.sos && response.alertId) {
      setAlertId(response.alertId);
      showToast({
        status: 'success',
        title: translate(TranslationKeys.sosAlertSuccess),
      });
      onOpenAlertInformationProvider();
      return;
    }
  };

  return { executeCommand };
};
