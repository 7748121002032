import {
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useRadioGroup,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FC } from 'react';
import { BsFillMicFill } from 'react-icons/bs';

import { ChakraAnimationBox } from 'src/components';
import { LANGUAGE_OPTIONS } from 'src/constants/speech-recognition.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider';
import { useASRAudio } from 'src/hooks/useAsrAudio';
import { useTranslate } from 'src/hooks/useTranslate';
import { TRANSCRIBE_LANGUAGE } from 'src/pages/messages/constants/language.constant';
import { ValueCallback } from 'src/types/common.type';

import { RadioCard } from './RadioCard';
import { VoiceAnimation } from './voice-animation';

interface RecordAudioDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onMessageSend: ValueCallback<string>;
  messageThreadId: number;
}

const RecordAudioDialog: FC<RecordAudioDialogProps> = ({
  isOpen,
  onClose,
  onMessageSend,
  messageThreadId,
}) => {
  const { translate } = useTranslate();
  const {
    handleLanguageChange,
    isRecording: isMicEnabled,
    transcribeMessage,
    handleInputChange,
    edit,
    handleEdit,
    onTranscriptionSend,
    restartRecording,
    resetState,
  } = useASRAudio(messageThreadId, onClose, onMessageSend);
  const { transcribeLanguage } = useHandsFreeOperationSetting();

  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: transcribeLanguage,
    onChange: value => handleLanguageChange(value as TRANSCRIBE_LANGUAGE),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{translate(TranslationKeys.startSpeaking)}</Text>
          <HStack pt='3' {...getRootProps()}>
            {_.map(LANGUAGE_OPTIONS, option => (
              <RadioCard
                key={option.name}
                {...getRadioProps({ value: option.value })}
              >
                {option.value}
              </RadioCard>
            ))}
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container
            display='flex'
            alignItems='center'
            justifyContent='center'
            marginTop='20px'
          >
            <ChakraAnimationBox>
              <VoiceAnimation isAnimating={isMicEnabled} />
            </ChakraAnimationBox>
          </Container>

          {edit ? (
            <Textarea
              noOfLines={4}
              overflowY='auto'
              size='md'
              height={130}
              top='10'
              value={transcribeMessage}
              onChange={e => handleInputChange(e)}
            />
          ) : (
            <Textarea
              overflowY='auto'
              size='md'
              height={130}
              border='none'
              top='10'
              focusBorderColor='transparent'
              value={transcribeMessage}
            />
          )}
        </ModalBody>
        <ModalFooter display='flex' justifyContent='space-between' mt='8'>
          <Flex>
            <IconButton
              aria-label='action button'
              rounded='full'
              variant='outline'
              as='span'
              colorScheme={isMicEnabled ? 'red' : 'green'}
              cursor='pointer'
              isRound
              size='lg'
              pl='2'
              pr='2'
              onClick={() => (isMicEnabled ? resetState() : restartRecording())}
            >
              <Icon
                as={BsFillMicFill}
                color={!isMicEnabled ? 'green.500' : 'red'}
                boxSize='8'
              />
            </IconButton>
          </Flex>
          <Flex>
            {!edit && (
              <Button
                colorScheme='blue'
                mr={3}
                onClick={handleEdit}
                disabled={isMicEnabled}
              >
                {translate(TranslationKeys.edit)}
              </Button>
            )}
            <Button colorScheme='blue' onClick={onTranscriptionSend}>
              {translate(TranslationKeys.send)}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RecordAudioDialog;
