import { useEffect } from 'react';
import { MdMic, MdMicOff } from 'react-icons/md';

import { AUTO_ENABLE_MIC_TIMEOUT } from 'src/constants/group-call.constant';
import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';

import FooterIconButton from './FooterIconButton';

interface MicrophoneButtonProps {
  isMicDisabled: boolean;
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = ({
  isMicDisabled,
}) => {
  const { isAudioMuted, toggleAudio } = usePeerConnection();

  // Automatically enable mic after receiver joined the call
  useEffect(() => {
    setTimeout(() => {
      if (!isMicDisabled) {
        toggleAudio();
      }
    }, AUTO_ENABLE_MIC_TIMEOUT);
  }, [isMicDisabled]);

  return (
    <FooterIconButton
      activeIcon={MdMic}
      inactiveIcon={MdMicOff}
      onToggleClick={toggleAudio}
      isActive={!isAudioMuted}
      aria-label='Toggle microphone'
      disabled={isMicDisabled}
    />
  );
};

export default MicrophoneButton;
