import { VStack, Table, TableContainer, Thead, Tr, Th } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { time24Hour } from 'src/utils';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';
import { PersonalCalls } from 'src/models/PersonalCall';
import { itemVariants } from 'src/constants/calls.constant';

import CallHistoryItem from './CallHistoryItem';
import { getSortedCallHistory } from '../utils/call.util';

interface CallHistoryProps {
  callerId: number;
  receiverId: number;
  personalCalls: PersonalCalls[] | undefined;
}

export const CallHistory: React.FC<CallHistoryProps> = ({
  callerId,
  receiverId,
  personalCalls,
}) => {
  return (
    <VStack
      as={motion.div}
      variants={itemVariants}
      initial='hidden'
      animate='visible'
      py='2'
    >
      <TableContainer
        w='full'
        maxH='52'
        overflowY='auto'
        css={CUSTOM_SCROLL_BAR_CSS}
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Joined</Th>
              <Th>Status</Th>
              <Th>Duration</Th>
            </Tr>
          </Thead>
          {/* render all items except the item at top */}
          {personalCalls &&
            getSortedCallHistory(personalCalls, callerId, receiverId).map(
              ({ id, session, createdAt }) => (
                <CallHistoryItem
                  key={id}
                  callerId={session.CallerId}
                  receiverId={session.ReceiverId}
                  joinedAt={session.joinedAt}
                  hasRejectedCall={session.hasRejected}
                  hasMissedCall={session.hasMissed}
                  isUnreachable={session.isUnreachable}
                  subtitle={time24Hour(createdAt)}
                  isVisible={false}
                  disconnectedAt={session.disconnectedAt}
                />
              ),
            )}
        </Table>
      </TableContainer>
    </VStack>
  );
};
