import { useToast } from '@chakra-ui/react';
import { createContext, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocalStorage } from 'react-use';

import { updateUserLanguage } from 'src/apis/users.api';
import { localStorageKeys } from 'src/constants/local-storage-keys.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { TRANSCRIBE_LANGUAGE } from 'src/pages/messages/constants/language.constant';
import { useUserDetails } from '../UserDetailsProvider';

const HFO_LOCAL_STORAGE_KEY = 'hfo-enable';
const TTS_LOCAL_STORAGE_KEY = 'tts-enable';

interface HandsFreeOperationSettingContextType {
  toggleHfoIcon: () => void;
  isHfoEnable: boolean | undefined;
  transcribeLanguage: TRANSCRIBE_LANGUAGE | undefined;
  changeLanguage: (language: TRANSCRIBE_LANGUAGE) => void;
  isTextToSpeechEnable: boolean | undefined;
  toggleTtsIcon: () => void;
}

export const HandsFreeOperationSettingContext =
  createContext<HandsFreeOperationSettingContextType>(null!);

const HandsFreeOperationSettingProvider: React.FC = ({ children }) => {
  const { translate, changeLanguage: changeAppLanguage } = useTranslate();
  const showToast = useToast();
  const { currentUser } = useUserDetails();
  const [isHfoEnable, setIsHfoEnable] = useLocalStorage(
    HFO_LOCAL_STORAGE_KEY,
    false,
  );

  const [isTextToSpeechEnable, setIsTextToSpeechEnable] = useLocalStorage(
    TTS_LOCAL_STORAGE_KEY,
    false,
  );

  const [transcribeLanguage, setTranscribeLanguage] =
    useLocalStorage<TRANSCRIBE_LANGUAGE>(
      localStorageKeys.languageLocalStorageKey,
      TRANSCRIBE_LANGUAGE.english,
    );

  const { mutate: updateUserLanguageMutate } = useMutation(updateUserLanguage);

  const toggleHfoIcon = () => {
    setIsHfoEnable(!isHfoEnable);
  };

  const toggleTtsIcon = () => {
    setIsTextToSpeechEnable(!isTextToSpeechEnable);
  };

  const changeLanguage = (language: TRANSCRIBE_LANGUAGE) => {
    if (currentUser) {
      updateUserLanguageMutate(language, {
        onSuccess: () => {
          setTranscribeLanguage(language);
          changeAppLanguage(language);
          window.location.reload();
        },
        onError: () => {
          showToast({
            title: translate(TranslationKeys.failToUpdateLang),
            status: 'error',
          });
        },
      });
      return;
    }
    setTranscribeLanguage(language);
    changeAppLanguage(language);
    window.location.reload();
  };

  useEffect(() => {
    if (currentUser) setTranscribeLanguage(currentUser.language);
  }, [currentUser]);

  useEffect(() => {
    if (transcribeLanguage) changeAppLanguage(transcribeLanguage);
  }, [transcribeLanguage]);

  const contextValue = {
    toggleHfoIcon,
    toggleTtsIcon,
    isHfoEnable,
    transcribeLanguage,
    changeLanguage,
    isTextToSpeechEnable,
  };

  return (
    <HandsFreeOperationSettingContext.Provider value={contextValue}>
      {children}
    </HandsFreeOperationSettingContext.Provider>
  );
};

const useHandsFreeOperationSetting = () => {
  const context = useContext(HandsFreeOperationSettingContext);
  if (!context) {
    throw new Error(
      'useHandsFreeOperationSetting must be used within a HandsFreeOperationSettingProvider',
    );
  }
  return context;
};

export { HandsFreeOperationSettingProvider, useHandsFreeOperationSetting };
