import { Variants } from 'framer-motion';

export const CONVERT_TO_PERCENTAGE = 100;

export const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const itemVariants: Variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};
