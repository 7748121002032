import { Icon } from '@chakra-ui/react';
import { BsMicFill, BsMicMuteFill } from 'react-icons/bs';

import { CallType } from 'src/types/call.type';

interface MicIconProps {
  isPeerMicEnabled: boolean | undefined;
  callType: CallType;
}

export const CallMicStatus: React.FC<MicIconProps> = ({
  isPeerMicEnabled,
  callType,
}) => {
  return (
    <Icon
      as={isPeerMicEnabled ? BsMicFill : BsMicMuteFill}
      color='white'
      boxSize='1.5rem'
      bottom='4'
      left={callType === CallType.Personal ? '4' : 'none'}
      right={callType === CallType.Group ? '4' : 'none'}
      position='absolute'
    />
  );
};
