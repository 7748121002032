import _ from 'lodash';

import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

const usePersonalCallSession = () => {
  const { callSessions } = useCallSessions();

  return _.head(callSessions);
};

export default usePersonalCallSession;
