import { Icon, IconButton } from '@chakra-ui/react';
import { useCallback } from 'react';
import { MdCallEnd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { leaveCallSession } from 'src/apis/call-sessions.api';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';

interface EndCallButtonProps {}

const EndGroupCallButton: React.FC<EndCallButtonProps> = () => {
  const history = useHistory();
  const { callSessions } = useCallSessions();

  const leaveCall = useCallback(() => {
    callSessions.forEach(session => leaveCallSession(session.id));
    history.goBack();
  }, [callSessions, history]);

  return (
    <IconButton
      aria-label='End Group Call'
      colorScheme='red'
      isRound
      size='lg'
      icon={<Icon as={MdCallEnd} fontSize='4xl' />}
      onClick={leaveCall}
    />
  );
};

export default EndGroupCallButton;
