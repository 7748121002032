import { CallSession } from 'src/models/CallSession.model';

type CallSessionUserId = CallSession['CallerId'] | CallSession['ReceiverId'];

export const getOtherUser = (
  callSession: CallSession,
  selfUserId: CallSessionUserId,
) => {
  return callSession.CallerId === selfUserId
    ? callSession.receiver
    : callSession.caller;
};

export const getOtherUserId = (
  callSession: CallSession,
  selfUserId: CallSessionUserId,
) => {
  return callSession.CallerId === selfUserId
    ? callSession.ReceiverId
    : callSession.CallerId;
};

export const getChildrenHeight = (
  heightRef: React.RefObject<HTMLDivElement>,
) => {
  return `calc(${heightRef.current?.clientHeight! / 2}px - 8px)`;
};
