import { Icon } from '@chakra-ui/react';
import { MdAddIcCall } from 'react-icons/md';

import { CustomTabs } from 'src/components';
import FloatingActionButton from 'src/components/FloatingActionButton';
import { TranslationKeys } from 'src/constants/translation-keys';
import useDialog from 'src/hooks/useDialog';
import { useTranslate } from 'src/hooks/useTranslate';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import SelectUserToCallModal from './components/SelectUserForCall';
import GroupCallsPanel from './panels/groupcall/GroupCallsPanel';
import PersonalCallsPanel from './panels/PersonalCallsPanel';

const CallsPage = () => {
  const { translate } = useTranslate();
  const [
    isSelectUserToCallModalOpen,
    openSelectUserToCallModal,
    closeSelectUserToCallModal,
  ] = useDialog();

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.calls)}>
      <CustomTabs
        firstTabPanel={<PersonalCallsPanel />}
        secondTabPanel={<GroupCallsPanel />}
      />
      <FloatingActionButton
        aria-label='Make Call Button'
        icon={<Icon as={MdAddIcCall} boxSize='5' />}
        onClick={openSelectUserToCallModal}
      />
      <SelectUserToCallModal
        isOpen={isSelectUserToCallModalOpen}
        onClose={closeSelectUserToCallModal}
      />
    </HeaderWrapper>
  );
};

export default CallsPage;
